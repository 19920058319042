<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon style="color:#202020;" :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Who's Going?!</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>

    <div v-if="attendees">
      <div v-if="attendees.length > 0">
        <ion-item v-for="attendee in attendees" :key="attendee">
          <div tabindex="0"></div>
          <ion-avatar slot="start">
            <img v-if="attendee.user.profile_photo_path != null && attendee.user.profile_photo_path != ''" :src="attendee.user.profile_photo_path">
            <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
          </ion-avatar>
          <ion-label>
            <h2>{{attendee.user.name}} <ion-text color="medium">@{{attendee.user.username}}</ion-text></h2>
            <h3 v-if="attendee.user.profile.short_description != null && attendee.user.profile.short_description != ''">
              {{attendee.user.profile.short_description}}
            </h3>
          </ion-label>
        </ion-item>
      </div>
      <div v-else class="ion-padding">
        Nobody has responded to this concert, yet. 
      </div>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonAvatar, IonLabel, IonText } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { close } from 'ionicons/icons';
import { useStore } from "vuex";
import apiClient from '../../services/api';

export default defineComponent({
  name: 'Atendees',
  props: ['postId'],
  data() {
    return {
      content: 'Content',
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonAvatar, IonLabel, IonText },
  setup(props) {
    const store = useStore();
    const attendees = ref([]);

    onMounted(() => {
      getAttendees()
    })

    function getAttendees() {
      apiClient.get('/api/get/rsvp/'+props.postId,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          attendees.value = response.data
        }).catch(error => {
          console.log(error)
        }); 
    }

    function closeModal() {
      store.commit('setDismissModal', true)
    }

    return {
      close, closeModal, attendees
    }
  }
});
</script>