<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeModal()">
          <ion-icon style="color:#202020;" :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-buttons slot="end">
        <ion-button v-if="bodyText != null && bodyText != ''" color="primary" @click="storePost()">
          Post
        </ion-button>
        <ion-button v-else color="light">
          Post
        </ion-button>
      </ion-buttons>
      <ion-title>Create Classified</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>

    <!-- 
      Category Not Selected 
    -->
    <div v-if="!category">
      <div>
        <div class="padding-top-md padding-right-md padding-left-md">
          <strong>Select a Category</strong>
        </div>
        <div class="padding-right-md">
          <IonItem>
            <div tabindex="0"></div>
            <IonLabel>Gig Offered</IonLabel>
            <IonRadio slot="start" value="gig" @click.prevent="setCategory('gig')" />
          </IonItem>
          <IonItem>
            <div tabindex="0"></div>
            <IonLabel>For Sale</IonLabel>
            <IonRadio slot="start" value="for-sale" @click.prevent="setCategory('for-sale')" />
          </IonItem>
        </div>
      </div>
    </div>
    <!-- 
      Category Selected 
    -->
    <div v-else>
      <!-- Sub Category -->
      <div v-if="!subCategory">
        <div>
          <div class="padding-top-md padding-right-md padding-left-md">
            <strong>Select a Sub Category</strong>
          </div>
          <div v-if="category == 'gig'" class="padding-right-md">
            <IonItem>
              <div tabindex="0"></div>
              <IonLabel>Booking</IonLabel>
              <IonRadio slot="start" value="booking" @click.prevent="setSubCategory('booking')" />
            </IonItem>
            <IonItem>
              <div tabindex="0"></div>
              <IonLabel>Gig Swap</IonLabel>
              <IonRadio slot="start" value="gig-swap" @click.prevent="setSubCategory('gig-swap')" />
            </IonItem>
            <IonItem>
              <div tabindex="0"></div>
              <IonLabel>Job Offered</IonLabel>
              <IonRadio slot="start" value="job" @click.prevent="setSubCategory('job')" />
            </IonItem>
          </div>
          <div v-if="category == 'for-sale'" class="padding-right-md">
            <IonItem>
              <div tabindex="0"></div>
              <IonLabel>Instruments</IonLabel>
              <IonRadio slot="start" value="booking" @click.prevent="setSubCategory('instruments')" />
            </IonItem>
            <IonItem>
              <div tabindex="0"></div>
              <IonLabel>Clothing</IonLabel>
              <IonRadio slot="start" value="gig-swap" @click.prevent="setSubCategory('clothing')" />
            </IonItem>
            <IonItem>
              <div tabindex="0"></div>
              <IonLabel>Tickets</IonLabel>
              <IonRadio slot="start" value="job" @click.prevent="setSubCategory('tickets')" />
            </IonItem>
          </div>
        </div>
      </div>
      <!-- Form -->
      <div v-else>
        <div class="padding-right-md">
          <div class="padding-top-md padding-left-md">
            <strong>Details</strong>
          </div>
          <div>
            <div v-if="scene">
              <div v-if="scene.type == 'metro'">
                <IonItem>
                  <div tabindex="0"></div>
                  <IonSelect :value="scene.id" v-model="selectedSceneId" :placeholder="scene.name+', '+scene.state" class="w-100">
                    <IonSelectOption v-for="item in items" :key="item" :value="item.id">{{item.name}}</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </div>
              <div v-else>
                No Scene
              </div>
            </div> 
            <div v-else>
              No Scene
            </div>
          </div>
          <div>
            <ion-item>
              <div tabindex="0"></div><!-- bug fix for safari 14 -->
              <ion-input v-model="title" placeholder="Enter a title"></ion-input>
            </ion-item>
          </div>
          <!-- Image -->
          <div v-if="imageUrl != null">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-img :src="imageUrl" />
          </div>
          <div>
            <TextWrapper 
              :store="store.state"
              input-type="textarea"
              :is-mentions="true" 
              :is-hashtags="true" 
              :has-button="false" 
              :set-delimeter="null"
              @input-data="getInputData" />
            </div>
        </div>
        <div v-if="category == 'for-sale'">
          <div>
            <ion-item>
              <div tabindex="0"></div><!-- bug fix for safari 14 -->
              <ion-input v-model="make" placeholder="Enter make"></ion-input>
            </ion-item>
          </div>
          <div>
            <ion-item>
              <div tabindex="0"></div><!-- bug fix for safari 14 -->
              <ion-input v-model="model" placeholder="Enter model"></ion-input>
            </ion-item>
          </div>
        </div>
        <div>
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-input v-model="price" placeholder="Enter price"></ion-input>
          </ion-item>
        </div>
        <div class="ion-padding">
          <ion-button v-if="imageUrl === null" slot="start" size="small" color="light" @click="takePicture()">
            <ion-icon :icon="camera"></ion-icon>
          </ion-button>
          <ion-button v-if="imageUrl != null" slot="start" size="small" color="danger" @click="imageUrl = null">
            <ion-icon :icon="trash"></ion-icon>
          </ion-button>  
        </div>
      </div>
    </div>

  </ion-content>

</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonRadio, IonImg, loadingController, toastController, IonInput, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { close, camera, trash } from 'ionicons/icons';
import { useStore } from "vuex";
import TextWrapper from '../TextWrapper.vue';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
import apiClient from '../../services/api'; 

export default defineComponent({
  name: 'ClassifiedForm',
  props: [],
  data() {
    return {
      content: 'Content',
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonRadio, TextWrapper, IonImg, IonInput, IonSelect, IonSelectOption },
  setup() {
    const store = useStore();
    const category = ref(null);
    const subCategory = ref(null);
    const title = ref(null);
    const bodyText = ref(null);
    const make = ref(null);
    const model = ref(null);
    const price = ref(null);
    const imageUrl = ref(null);
    const timeout = { default: 6000 }
    const toastMessage = ref(null);
    const linkData = ref([]);
    const linkDescription = ref(null);
    const linkTitle = ref(null);
    const linkImage = ref(null);
    const url = ref(null);
    const scene = ref([]);
    const items = ref([]);
    const selectedSceneId = ref(0);

    onMounted(() => {
      scene.value = store.state.authUser.scene
      getItems()
    })

    function closeModal() {
      store.commit('setDismissModal', true)    
    }

    function setCategory(val) {
      category.value = val
    }

    function setSubCategory(val) {
      subCategory.value = val
    }

    function getInputData(e) {
      if(e) {
        bodyText.value = e.text
        linkData.value = e.linkData
      }
    }

    async function takePicture() {
      let isAvailable = true;
      if (!isAvailable) {
        // Have the user upload a file instead
        alert("No Camera Aailable");
      } else {
        // Otherwise, make the call:
        try {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
          });
          console.log("image", image);
          // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
          imageUrl.value = image.dataUrl;
          // can be set to the src of an image now
          console.log(image);
        } catch (e) {
          console.log("error", e);
        }
      }
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function storePost() {
      presentLoading()
      apiClient.post('/api/store/post', 
        { 
          title: title.value,
          body: bodyText.value, 
          status: 1, 
          post_type: 'classified' ,
          image: imageUrl.value,
          url: url.value,
          genres: null,
          link_image: linkImage.value,
          link_title: linkTitle.value,
          link_description: linkDescription.value,
          external: 1,
          category: category.value,
          sub_category: subCategory.value,
          make: make.value,
          model: model.value,
          price: price.value,
          scene_id: selectedSceneId.value
          //mentions: mentions.value,
          //hashtags: hashtags.value       
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            toastMessage.value = 'Your post has been created.'
            openToast()
            //isError.value = false
           // errors.value = []
            resetForm()
            // Refresh feed
            store.commit('setFeedTrigger', true);
            // Clear text wrapper
            store.commit('setGenericTrigger', true)
            closeModal()
            // Redirect home
            //router.push('home')

          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'There was a problem.'
            openToast()
            //isError.value = true
            //errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
        }).catch(error => {
          console.log(error)
          // credentials didn't match
          //isError.value = true
          //errors.value = error.response.data.errors
        });        

    }

    function resetForm() {
      bodyText.value = null
      imageUrl.value = null
    }

    function getItems() {
      //presentLoading()
      apiClient.get('/api/get/scenes/type/metro', {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        items.value = response.data
      }).catch(error => {
        console.log(error)  
      });      
    }

    return {
      store, close, closeModal, category, subCategory, setSubCategory, setCategory, title, getInputData, bodyText, camera, imageUrl, takePicture, trash, storePost, resetForm, linkData, linkDescription, linkImage, linkTitle, url, make, model, price, scene, items, selectedSceneId
    }
  }
});
</script>