<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon style="color:#202020;" :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div v-if="likes">
      <div v-if="likes.length > 0">
        <ion-item v-for="like in likes" :key="like">
          <div tabindex="0"></div>
          <ion-avatar slot="start">
            <img v-if="like.image != null && like.image != ''" :src="like.image">
            <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
          </ion-avatar>
          <ion-label>
            <h2>{{like.name}} <ion-text color="medium">@{{like.username}}</ion-text></h2>
            <h3 v-if="like.short_description != null && like.short_description != ''">
              {{like.short_description}}
            </h3>
          </ion-label>
        </ion-item>
      </div>
      <div v-else class="ion-padding">
        This post has not been liked. 
      </div>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonAvatar, IonLabel, IonText } from '@ionic/vue';
import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { useStore } from "vuex";

export default defineComponent({
  name: 'LikeList',
  props: ['title', 'likes', 'likeType'],
  data() {
    return {
      content: 'Content',
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonAvatar, IonLabel, IonText },
  setup(props) {
    const store = useStore();

    function closeModal() {
      if(props.likeType == 'post') {
        store.commit('setDismissPostLikeModal', true)
      }
      if(props.likeType == 'comment') {
        store.commit('setDismissCommentLikeModal', true)
      }
      if(props.likeType == 'comment-reply') {
        store.commit('setDismissCommentReplyLikeModal', true)
      }      
    }

    return {
      close, closeModal
    }
  }
});
</script>