<template>
	<div>
		<!-- Results -->
		<div v-if="filteredProfiles.length > 0">
			<ion-grid>
				<ion-row>
					<ion-col size="4" v-for="profile in filteredProfiles" :key="profile" class="text-center ion-text-center">
						
						<ion-thumbnail class="margin-auto">
							<img v-if="profile.image != null && profile.image != ''" :src="profile.image" class="w-100 img-avatar" @click.prevent="updateRoute(profile.slug)">
							<img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg" class="w-100 img-avatar" @click.prevent="updateRoute(profile.slug)">
						</ion-thumbnail>
						<p style="line-height:14px;padding:0px;margin:5px 0px 15px 0px;" class="profile-name text-medium-shade">{{trimString(profile.name, 18)}}</p>
					</ion-col>					
				</ion-row>
			</ion-grid>
		</div>
		<!-- No Results -->
		<div v-else class="ion-padding">
			No Profiles
		</div>
	</div>
</template>

<script>
import { loadingController, IonCol, IonGrid, IonRow, IonThumbnail } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent( {
	components: { IonCol, IonGrid, IonRow, IonThumbnail },
	name: 'ProfileAvatars',
	props: ['profileType', 'limit', 'view', 'sceneId'],
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const profiles = ref([]);
		const filteredProfiles = ref([]);
		const timeout = { default: 6000 }

		onMounted(() => {
			if(props.sceneId > 0) {
				getProfiles(props.profileType)
			}
		})

		function getProfiles(val) {
			presentLoading()
			if(props.sceneId > 0) {
				apiClient.get('/api/get/profiles/type/' + val + '/limit/'+ props.limit +'/scene/' + props.sceneId,
					{
						headers: {
							'Authorization':  `Bearer ${store.state.authToken}`
						}
					}).then(response => {
						profiles.value = response.data
						doFilteredProfiles()
					}).catch(error => {
						console.log(error)
					});     
			}
   
		}

		function doFilteredProfiles() {
			if(profiles.value) {
				for(var i=0;i<profiles.value.length;i++) {
					let image = null;
					let slug = null;
					if(props.profileType == 'user') {
						image = profiles.value[i].profile_photo_path
						slug = profiles.value[i].username
					} else {
						image = profiles.value[i].image
						slug = profiles.value[i].slug	
					}
					filteredProfiles.value.push({
						id: profiles.value[i].id,
						slug: slug,
						name: profiles.value[i].name,
						image: image
					})
				}
			}
		}

		function updateRoute(val) {
			router.push('/profile/' + val)
		}

		function trimString(val, num) {
			if(val.length > num) {
				let trimmedString = val.substring(0, num)
				return trimmedString + '...'
			} else {
				return val
			}
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		return {
			profiles, filteredProfiles, getProfiles, router, authUser, updateRoute, trimString
		}
	},
	watch: {

	}
});
</script>

<style scoped>
#genre-chips ion-segment-button {
	--indicator-box-shadow: transparent!important;
	margin-right: 5px;
	--background: var(--ion-color-light-shade);
	--color: var(--ion-color-dark);
	--background-checked: var(--ion-color-secondary);
	--color-checked: black;
	--indicator-color : transparent!important; /* this solution */
	--line-height: 25px!important;
	min-width:auto!important;
	--border: 0px!important;
	border: 0px!important;
	
}

#genre-chips ion-segment-button::after {
	--border: 0px!important;
}

#genre-chips ion-segment{
	--background: var(--ion-color-light-tint);
	--line-height: 25px!important;
}
ion-button {
	margin:2px 2px 0px 0px;
	--padding-end: 0.75em;
	--padding-start: 0.75em;
	--background: var(--ion-color-light);
	--color: var(--ion-color-dark);
}
.btn-row-bg {
	background: var(--ion-color-light);
}
ion-icon {
	font-size:14px;
}
.profile-name {
	font-size:12px;
}
ion-thumbnail {
   --size: 80px;
}
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>