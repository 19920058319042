<template>
	<div>
		<!-- 
			Feed 
		-->
		<!--{{feedType}} | {{postGenres}} Feed Genres {{genres}} | Feed Scenes {{scenes}}
		| {{profileId}} | {{placeId}}-->
		<!-- There are posts -->
		<div v-if="matchedItems && matchedItems.length > 0" style="padding-top:0px;">
			<div v-if="postType != 'concerts'">
				<ion-select value="feed" v-model="feedType" interface="popover">
					<ion-select-option value="feed">All Posts</ion-select-option>
					<ion-select-option value="classified">Classifieds</ion-select-option>
				</ion-select>
			</div>

			<div v-for="post in matchedItems" :key="post">
				<!-- Post -->
				<div v-if="post.post_type == 'post' || post.post_type == 'article'">
					<ArticleCard :post="post" :is-auth="true" display="feed" :like-count="post.like_count" :comment-count="post.comment_count" />
				</div>
				<!-- Concert -->
				<div v-if="post.post_type == 'concert'">
					<ConcertCard :post="post" :is-auth="true" display="feed" :like-count="post.like_count" :comment-count="post.comment_count" />
				</div>
				<!-- Classified -->
				<div v-if="post.post_type == 'classified'">
					<ArticleCard :post="post" :is-auth="true" display="feed" :like-count="post.like_count" :comment-count="post.comment_count" />
				</div>
			</div>
		</div>
		<!-- There are no posts -->
		<div v-else class="padding-md">
			There are currently no posts
		</div>
	
	</div>
</template>

<script>
import { loadingController, toastController, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, onMounted, computed, ref } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import ArticleCard from './ArticleCard.vue';
import { useRouter } from 'vue-router';
import ConcertCard from './ConcertCard.vue'
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

export default defineComponent( {
	components: { ArticleCard, ConcertCard, IonSelect, IonSelectOption },
	name: 'PostFeed',
	props: ['postGenres', 'postScenes', 'postType', 'feedSettings', 'sceneId'],
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const feedTrigger = computed(() => store.state.feedTrigger);
		const posts = ref(null);
		const genres = ref([]);
		const scenes = ref([]);
		const postSlug = ref(null);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }
		const matchedItems = ref([]);
		const feedType = ref('feed');

		onMounted(() => {
			if(props.sceneId > 0) {
				getFeed()
			}
		})

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function getFeed() {
			posts.value = null
			presentLoading()
			// Feed
			genres.value = props.postGenres
			scenes.value = props.postScenes

			let apiUrl = '/api/user/feed/scene' 

			if(props.postType == 'concerts') {
				apiUrl = '/api/user/feed/concerts/scene'
			}
			if(props.postType == 'classifieds') {
				apiUrl = '/api/user/feed/classifieds/scene' 
			}

			apiClient.post(apiUrl, 
				{
					scene_id: props.sceneId,
					genres: genres.value,  
					scenes: scenes.value,        
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data.message == 'success') {
						posts.value = response.data.posts
						store.commit('setFeedTrigger', false)
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						toastMessage.value = 'You must be signed in.'
						openToast()          
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						toastMessage.value = 'You must be signed in.'
						openToast()
					}
				}).catch(error => {
					console.log(error)
				});  
		}

		function viewPost(slug) {
			router.push('/post/'+slug)
		}

		function updateRoute(val) {
			router.push(val)
		}

		function viewGenre(slug) {
			window.location = '/genre/'+slug
		}

		function updateMatchedItems() {
			matchedItems.value = []
			if(feedType.value == 'feed') {
				matchedItems.value = null
				matchedItems.value = posts.value
			} else {
				for(var i=0;i<posts.value.length;i++) {
					if(posts.value[i].post_type == feedType.value) {
						matchedItems.value.push(posts.value[i])
					}
				}	
			}
		}

		return {
			getFeed, authUser, posts, updateRoute, viewPost, router, postSlug, genres, scenes, store, viewGenre, feedTrigger, matchedItems, feedType, updateMatchedItems
		}
	},
	watch: {
		postGenres: {
			handler() {
				this.getFeed()
			},
			deep:true
		},
		feedOption: function() {
			this.getFeed()
		},
		feedTrigger: function() {
			if(this.feedTrigger === true) {
				this.getFeed()
			}
		},
		sceneId: function() {
			if(this.sceneId > 0) {
				this.getFeed()
			}
		},
		posts: {
			handler() {
				this.matchedItems = []
				if(this.posts && this.posts.length > 0) {
					this.matchedItems = this.posts 
				}
			},
			deep:true
		},
		feedType: function() {
			this.updateMatchedItems()
		}
	}
});
</script>

<style scoped>
	#classified-category-segment ion-segment {
		padding:10px 2px 0px 2px;
		border-radius: 0px!important;
		--background: white;

	}
	#classified-category-segment ion-segment-button {
		--indicator-box-shadow: transparent!important;
		margin-right: 5px;
		--background: var(--ion-color-light-shade);
		--color: var(--ion-color-dark);
		--background-checked: var(--ion-color-secondary);
		--color-checked: black;
		--indicator-color : transparent!important; /* this solution */
		--line-height: 25px!important;
		min-width:auto!important;
	}

	ion-searchbar {
		margin-top:0px;
		margin-bottom:5px;
		height:50px;
	}

	ion-card {
		margin:0px 0px 10px 0px;
		border-radius: 0px;
	}

	ion-grid {
		--ion-grid-padding: 0px!important;
		padding: 0px!important;
	}

	ion-col {
		padding: 0px!important;
	}

	ion-row {
		padding: 0px!important;
	}

	#genre-chips ion-segment{
		--background: var(--ion-color-light-tint);
		--line-height: 25px!important;
	}
	.sm-padding {
		padding:5px!important;
	}
	.text-cap {
		text-transform:capitalize!important;
	}
	.small-text {
		font-size:14px;
	}
	.text-right {
		text-align: right!important;
	}
	.event-detail {

	}
	.border-bottom {
		padding-bottom:5px;
		margin-bottom:5px;
		border-bottom:1px solid #f4f5f8!important;
	}
	.border-top {
		padding-top:5px;
		margin-top:5px;
		border-top:1px solid #f4f5f8!important;
	}
	.bold {
		font-weight: bold;
	}
	.text-medium {
		color:#989aa2!important;
	}
	.text-dark {
		color:#73767d!important;
	}
	ion-icon {
		font-size:14px;
	}
</style>
