<template>
  <div>
    <!-- Input -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-input v-model="textString" placeholder="Enter genre"></ion-input>
    </ion-item>

    <!-- Button -->
    <ion-item v-if="textString != null && textString != ''">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-button @click.prevent="storeGenre()">Add Genre</ion-button>
    </ion-item>

    <!-- Matched Items -->
    <div v-if="matchedItems">
      <div v-if="matchedItems.length > 0">
        <!-- Textarea -->
        <ion-item v-for="item in matchedItems" :key="item">
          <div tabindex="0"></div>
          <ion-label position="stacked">
            {{item.name}}
          </ion-label>
          <ion-button slot="end" color="secondary" @click.prevent="selectGenre(item)">
            Select
          </ion-button>
        </ion-item>
      </div>
    </div>
  </div>
</template>

<script>
import { IonItem, IonInput, IonButton, IonLabel } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
  name: 'GenreSearch',
  props: [],
  components: { IonItem, IonInput, IonButton, IonLabel },
  setup() {
    const store = useStore();
    const textString = ref(null);
    const matchedItems = ref([]);
    const genres = ref([]);

    onMounted(() => {
      getGenres()
    })

    function getGenres() {
      //presentLoading()
      apiClient.get('/api/get/genres', {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        genres.value = response.data
      }).catch(error => {
        console.log(error)  
      });          
    }

    function searchGenres() {
      matchedItems.value = []
      if(genres.value) {
        // Loop through the collection
        for(var i=0; i<genres.value.length;i++) {
          var genreName = textString.value.toLowerCase()
          var str = genres.value[i].name.toLowerCase()

          // Check if collection has
          if(str.includes(genreName)) { // includes may not be the right method here

            matchedItems.value.push({
              id: genres.value[i].id,
              name: genres.value[i].name,
              slug: genres.value[i].slug,
            })

          }
        }
      }

    }

    function selectGenre(gen) {
      let g = {
        id: gen.id,
        name: gen.name,
        slug: gen.slug,
      }
      this.dataUp(g, 'genre')
      this.matchedItems = []
      this.textString = null
    }


    function storeGenre() {
      //presentLoading()
      apiClient.post('/api/post/genre', 
        { 
          name: textString.value, 
          status: 1
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            console.log(response.data)
            textString.value = null
            matchedItems.value = []
            this.dataUp({
              id: response.data.genre.id,
              name: response.data.genre.name,
              slug: response.data.genre.slug,
            })
            //toastMessage.value = 'Your post has been created.'
            //openToast()
            //isError.value = false
            //errors.value = []
            //resetForm()
            //store.commit('setFeedTrigger', true);
            //router.push('home')
          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            //toastMessage.value = 'There was a problem.'
            //openToast()
            //isError.value = true
            //errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            //toastMessage.value = 'You must be signed in to update your profile.'
            //openToast()
          }
        }).catch(error => {
          console.log(error)
          // credentials didn't match
          //isError.value = true
          //errors.value = error.response.data.errors
        });        

    }

    return {
      store, textString, matchedItems, genres, selectGenre, searchGenres, storeGenre
    }
  },
  methods: {
    dataUp: function(gen) {
      this.$emit('get-the-genre', {
        id: gen.id,
        name: gen.name,
        slug: gen.slug,
      })
    }
  },
  watch: {
    textString: function() {
      if(this.textString != null && this.textString != '') {
        if(this.textString.length >= 2) {
          this.searchGenres()
        }
      }
    },
  }
});
</script>