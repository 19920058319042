<template>
  <div>
    <div v-if="selectedGenres && step === 2">

      <ion-chip v-for="(item, index) in selectedGenres" :key="item" @click.prevent="removeGenre(index)">
        <ion-label> {{item.name}}</ion-label>
        <ion-icon name="close-circle"></ion-icon>
      </ion-chip>

      <div v-if="selectedGenres.length > 0">
        <ion-button @click.prevent="step = 3" size="small">Next</ion-button>
      </div>

    </div>
    <!-- Step 1 -->
    <div v-if="step === 1">
      <!-- Input -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-input v-model="textString" placeholder="Enter artist name"></ion-input>
      </ion-item>
      <!-- Button -->
      <ion-item v-if="textString != null && textString != ''">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-button v-if="addArtistForm === false" @click.prevent="addArtistForm = true">Add Artist</ion-button>
        <ion-button color="danger" v-if="addArtistForm === true" @click.prevent="addArtistForm = false">Cancel</ion-button>
      </ion-item>
      <!-- Add Form -->
      <div v-if="addArtistForm === true">
        <!-- City -->
        <ion-item>
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-input v-model="city" placeholder="City"></ion-input>
        </ion-item>
        <!-- State -->
        <div v-if="country === 'US'">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label position="stacked">Scene</ion-label>
            <ion-select placeholder="Select One" v-model="sceneId">
              <ion-select-option v-for="scene in scenes" :key="scene" :value="scene.id">
                {{scene.name}}, {{scene.state}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label position="stacked">State</ion-label>
            <ion-select placeholder="Select One" v-model="state">
              <ion-select-option v-for="item in states" :key="item" :value="item.abbreviation">
                {{item.full}}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </div>
        <div v-else> 
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-input v-model="state" placeholder="State or province"></ion-input>
          </ion-item>
        </div>
        <ion-item>
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-label position="stacked">Country</ion-label>
          <ion-select placeholder="Select One" v-model="country">
            <ion-select-option v-for="item in countries" :key="item" :value="item.slug.toUpperCase()">
              {{item.name}}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <!-- Website -->
        <ion-item>
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-input v-model="url" placeholder="Website or social media URL"></ion-input>
        </ion-item>
        <!-- Submit Venue -->
        <ion-item v-if="isValidated === true">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-button @click.prevent="step = 2">Next</ion-button>
        </ion-item>
      </div>

    </div>

    <!-- Step 2 -->
    <div v-if="step === 2">
      <!-- Genres -->
      <GenreSearch @get-the-genre="getGenre" />
    </div>

    <!-- Step 3 -->
    <div v-if="step === 3">
      <!-- Image -->
      <div v-if="imageUrl != null">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-img :src="imageUrl" />
      </div>

      <div class="ion-padding">
        <ion-button v-if="imageUrl === null" slot="start" size="small" color="light" @click="takePicture()">
          <ion-icon :icon="camera"></ion-icon>
        </ion-button>
        <ion-button v-if="imageUrl != null" slot="start" size="small" color="danger" @click="imageUrl = null">
          <ion-icon :icon="trash"></ion-icon>
        </ion-button>  
      </div>

      <div>
        <ion-button v-if="imageUrl === null || imageUrl == ''" size="small" @click.prevent="doArtist()">Skip</ion-button>
        <ion-button v-if="imageUrl !== null && imageUrl != ''" size="small" @click.prevent="doArtist()">Review</ion-button>
      </div>
    </div>

    <!-- 
      Step 4 / Preview
    -->
    <div v-if="step === 4">
      <ion-grid v-if="artist">
        <!-- Image -->
        <ion-row v-if="imageUrl">
          <ion-col class="text-center">
            <img :src="imageUrl" >
          </ion-col>
        </ion-row>
        <!-- Name -->
        <ion-row v-if="artist.name">
          <ion-col class="text-center">
            {{artist.name}}
          </ion-col>
        </ion-row>
        <!-- Location -->
        <ion-row>
          <ion-col class="text-center">
            <div v-if="artist.city != null && artist.city != ''">{{artist.city}}</div> 
            <p>
              <span v-if="artist.state != null && artist.state != ''">{{artist.state}}&nbsp;</span>{{artist.country}}
            </p>
          </ion-col>
        </ion-row>
        <!-- URl -->
        <ion-row v-if="artist.url != null && artist.url != ''">
          <ion-col class="text-center">
            {{artist.url}}
          </ion-col>
        </ion-row>
        <!-- Genres -->
        <ion-row v-if="artist.genres">
          <ion-col class="text-center">

            <ion-chip v-for="item in artist.genres" :key="item">
              <ion-label> {{item.name}}</ion-label>
            </ion-chip>

          </ion-col>
        </ion-row>
      </ion-grid>
      <!-- Submit -->
      <ion-item>
        <div tabindex="0"></div>
        <ion-button @click.prevent="storeArtist()">
          Submit
        </ion-button>
      </ion-item>
    </div>

    <!-- Matched Items -->
    <div v-if="matchedItems">
      <div v-if="matchedItems.length > 0">
        <!-- Textarea -->
        <ion-item v-for="item in matchedItems" :key="item">
          <div tabindex="0"></div>
          <ion-label position="stacked">
            {{item.name}}
          </ion-label>
          <ion-button slot="end" color="secondary" @click.prevent="selectArtist(item)">
            Select
          </ion-button>
        </ion-item>
      </div>
    </div>

  </div>
</template>

<script>
import { loadingController, toastController, IonItem, IonInput, IonButton, IonLabel, IonSelect, IonSelectOption, IonChip, IonIcon, IonGrid, IonRow, IonCol, IonImg } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import GenreSearch from './GenreSearch.vue';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
import { camera, trash } from 'ionicons/icons';

export default defineComponent({
  name: 'ArtistSearch',
  props: [],
  components: { IonItem, IonInput, IonButton, IonLabel, IonSelect, IonSelectOption, GenreSearch, IonChip, IonIcon, IonGrid, IonRow, IonCol, IonImg },
  setup() {
    const store = useStore();
    const step = ref(1);
    const textString = ref(null);
    const genreString = ref(null);
    const imageUrl = ref(null);
    const city = ref(null);
    const state = ref(null);
    const country = ref('US');
    const artist = ref([]);
    const artists = ref([]);
    const scenes = ref([]);
    const sceneId = ref(0);
    const matchedItems = ref([]);
    const matchedGenres = ref([]);
    const addArtistForm = ref(false);
    const isValidated = ref(false);
    const states = ref([]);
    const countries = ref([]);
    const categoryId = ref(14);
    const url = ref(null);
    const selectedGenres = ref([]); 
    const timeout = { default: 6000 }
    const toastMessage = ref(null);

    onMounted(() => {
      getArtists()
      getStates()
      getcountries()
      getScenes()
    })

    function getArtists() {
      apiClient.get('/api/get/all-artists',
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          artists.value = response.data
        }).catch(error => {
          console.log(error)
        }); 
    }

    function searchArtists() {
      matchedItems.value = []
      if(artists.value) {
        // Loop through the collection
        for(var i=0; i<artists.value.length;i++) {
          var artistName = textString.value.toLowerCase()
          var str = artists.value[i].name.toLowerCase()

          // Check if collection has
          if(str.includes(artistName)) { // includes may not be the right method here

            matchedItems.value.push({
              id: artists.value[i].id,
              name: artists.value[i].name,
              slug: artists.value[i].slug,
              city: artists.value[i].city,
              state: artists.value[i].state,
              genres: artists.value[i].genres
            })

          }
        }
      }

    }

    function doArtist() {
      artist.value = {
        name: textString.value,
        city: city.value,
        state: state.value,
        country: country.value,
        url: url.value,
        image: imageUrl.value,
        genres: selectedGenres.value
      }
      step.value = 4
    }

    function selectArtist(art) {
      let a = {
        id: art.id,
        name: art.name,
        slug: art.slug,
        city: art.city,
        state: art.state,
        role_id: 3,
        genres: art.genres
      }
      this.dataUp(a)
    }

    function getScenes() {
      apiClient.get('/api/get/scenes/type/metro',
      {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        scenes.value = response.data
      }).catch(error => {
        console.log(error)
      });       
    }

    function getStates() {
      apiClient.get('/api/get/states',
      {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        states.value = response.data
      }).catch(error => {
        console.log(error)
      });       
    }

    function getcountries() {
      apiClient.get('/api/get/scenes/type/country', {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        countries.value = response.data
      }).catch(error => {
        console.log(error)  
      });      
    }

    function storeArtist() {
      presentLoading()
      apiClient.post('/api/post/page', 
        {
          category_id: categoryId.value,  
          name: textString.value, 
          address_required: 0,
          street_address: null, 
          city: city.value,
          state: state.value,
          zip_code: null,
          country: country.value, 
          image: imageUrl.value,
          description: null,
          status: 1,
          url: url.value,
          scene_id: sceneId.value,
          genres: selectedGenres.value 
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            console.log(response.data.page)
            this.dataUp(response.data.page)
            toastMessage.value = 'You have successfully added an artist page.'
            openToast()
            step.value = 1
            //toastMessage.value = 'Your post has been created.'
            //openToast()
            //isError.value = false
            //errors.value = []
            resetForm()
            //store.commit('setFeedTrigger', true);
            //router.push('home')
          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            //toastMessage.value = 'There was a problem.'
            //openToast()
            //isError.value = true
            //errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            //toastMessage.value = 'You must be signed in to update your profile.'
            //openToast()
          }
        }).catch(error => {
          console.log(error)
          // credentials didn't match
          //isError.value = true
          //errors.value = error.response.data.errors
        });        

    }

    function resetForm() {
      textString.value = null
      city.value = null
      state.value = null
      country.value = 'US'
      url.value = null
      imageUrl.value = null
    }

    function removeGenre(i) {
      selectedGenres.value.splice(i, 1)
    }

    async function takePicture() {
      let isAvailable = true;
      if (!isAvailable) {
        // Have the user upload a file instead
        alert("No Camera Aailable");
      } else {
        // Otherwise, make the call:
        try {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
          });
          console.log("image", image);
          // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
          imageUrl.value = image.dataUrl;
          // can be set to the src of an image now
          console.log(image);
        } catch (e) {
          console.log("error", e);
        }
      }
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {

          }
        }]
      })
      return toast.present();
    }

    return {
      store, artist, artists, textString, searchArtists, matchedItems, addArtistForm, isValidated, imageUrl, city, state, country, states, countries, storeArtist, selectArtist, categoryId, url, step, matchedGenres, genreString, selectedGenres, removeGenre, takePicture, doArtist, camera, trash, scenes, sceneId
    }
  },
  methods: {
    validateForm: function() {
      if(this.textString != null && this.textString != '') {
       
        if(this.city != null && this.city != '') {
          this.isValidated = true
        } else {
          this.isValidated = false
        }
        
      } else {
        this.isValidated = false
      }
    },
    dataUp: function(art) {
      this.$emit('get-the-artist', {
        id: art.id,
        name: art.name,
        slug: art.slug,
        city: art.city,
        state: art.state,
        role_id: 3,
        genres: art.genres
      })
    },
    getGenre: function(e) {
      if(e) {
        this.selectedGenres.push(e)
      }
    }
  },
  watch: {
    textString: function() {
      if(this.textString != null && this.textString != '') {
        if(this.textString.length >= 2) {
          this.searchArtists()
          this.validateForm()
        }
      }
    },
    city: function() {
      this.validateForm()
    },
    state: function() {
      this.validateForm()
    },
    url: function() {
      this.validateForm()
    },
    addArtistForm: function() {
      if(this.addArtistForm === true) {
        this.matchedItems = []
      }
    }
  }
});
</script>