<template>
  <div>

    <!-- Input -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-input v-model="textString" placeholder="Enter page name"></ion-input>
    </ion-item>

    <!-- Button -->
    <ion-item v-if="textString != null && textString != ''">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-button v-if="addPageForm === false" @click.prevent="addPageForm = true">Add Page</ion-button>
      <ion-button color="danger" v-if="addPageForm === true" @click.prevent="addPageForm = false">Cancel</ion-button>
    </ion-item>

    <!-- Add Form -->
    <div v-if="addPageForm === true">
      <!-- Address -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-input v-model="address" placeholder="Street address"></ion-input>
      </ion-item>
      <!-- Cat -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-label position="stacked">Category</ion-label>
        <ion-select placeholder="Select One" v-model="categoryId">
          <ion-select-option v-for="item in pageCategories" :key="item" :value="item.id">
            {{item.name}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <!-- City -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-input v-model="city" placeholder="City"></ion-input>
      </ion-item>
      <!-- State -->
      <ion-item v-if="country === 'US'">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-label position="stacked">State</ion-label>
        <ion-select placeholder="Select One" v-model="state">
          <ion-select-option v-for="item in states" :key="item" :value="item.abbreviation">
            {{item.full}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item v-else>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-input v-model="state" placeholder="State or province"></ion-input>
      </ion-item>
      <!-- Zip Code -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-input v-model="zipCode" placeholder="Postal code"></ion-input>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-label position="stacked">Country</ion-label>
        <ion-select placeholder="Select One" v-model="country">
          <ion-select-option v-for="item in countries" :key="item" :value="item.slug.toUpperCase()">
            {{item.name}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <!-- Website -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-input v-model="url" placeholder="Website or social media URL"></ion-input>
      </ion-item>
      <!-- Submit Venue -->
      <ion-item v-if="isValidated === true">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-button @click.prevent="storePage()">Save Page</ion-button>
      </ion-item>
    </div>

    <!-- Matched Items -->
    <div v-if="matchedItems">
      <div v-if="matchedItems.length > 0">
        <!-- Textarea -->
        <ion-item v-for="item in matchedItems" :key="item">
          <div tabindex="0"></div>
          <ion-label position="stacked">
            {{item.name}}
          </ion-label>
          <ion-button color="secondary" slot="end" @click.prevent="selectPage(item)">
            Select
          </ion-button>
        </ion-item>
      </div>
    </div>
  </div>
</template>

<script>
import { IonItem, IonInput, IonButton, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
  name: 'PageSearch',
  props: [],
  components: { IonItem, IonInput, IonButton, IonLabel, IonSelect, IonSelectOption },
  setup() {
    const store = useStore();
    const textString = ref(null);
    const address = ref(null);
    const city = ref(null);
    const state = ref(null);
    const zipCode = ref(null);
    const country = ref('US');
    const page = ref([]);
    const pages = ref([]);
    const pageCategories = ref([]);
    const categoryId = ref(0);
    const matchedItems = ref([]);
    const addPageForm = ref(false);
    const isValidated = ref(false);
    const states = ref([]);
    const countries = ref([]);
    const url = ref(null);

    onMounted(() => {
      getPages()
      getPageCategories()
      getStates()
      getcountries()
    })

    function getPages() {
      apiClient.get('/api/get/pages',
      {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        pages.value = response.data
      }).catch(error => {
        console.log(error)
      });       
    }

    function getPageCategories() {
      apiClient.get('/api/get/categories',
      {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        pageCategories.value = response.data
      }).catch(error => {
        console.log(error)
      });       
    }

    function searchPages() {
      matchedItems.value = []
      if(pages.value) {
        // Loop through the collection
        for(var i=0; i<pages.value.length;i++) {
          var pageName = textString.value.toLowerCase()
          var str = pages.value[i].name.toLowerCase()

          // Check if collection has
          if(str.includes(pageName)) { // includes may not be the right method here

            matchedItems.value.push({
              id: pages.value[i].id,
              name: pages.value[i].name,
              slug: pages.value[i].slug,
              city: pages.value[i].city,
              state: pages.value[i].state,
            })

          }
        }
      }

    }

    function selectPage(selectedPage) {
      let p = {
        id: selectedPage.id,
        name: selectedPage.name,
        slug: selectedPage.slug,
        city: selectedPage.city,
        state: selectedPage.state,
      }
      this.dataUp(p)
    }

    function getStates() {
      apiClient.get('/api/get/states',
      {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        states.value = response.data
      }).catch(error => {
        console.log(error)
      });       
    }

    function getcountries() {
      apiClient.get('/api/get/scenes/type/country', {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        countries.value = response.data
      }).catch(error => {
        console.log(error)  
      });      
    }

    function storePage() {
      //presentLoading()
      apiClient.post('/api/post/page', 
        {
          category_id: categoryId.value,  
          name: textString.value, 
          address_required: 1,
          street_address: address.value, 
          city: city.value,
          state: state.value,
          zip_code: zipCode.value,
          country: country.value, 
          image: null,
          description: null,
          status: 1,
          url: url.value,
          scene_id: 0 
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            console.log(response.data.page)
            this.dataUp(response.data.page)
            //toastMessage.value = 'Your post has been created.'
            //openToast()
            //isError.value = false
            //errors.value = []
            //resetForm()
            //store.commit('setFeedTrigger', true);
            //router.push('home')
          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            //toastMessage.value = 'There was a problem.'
            //openToast()
            //isError.value = true
            //errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            //toastMessage.value = 'You must be signed in to update your profile.'
            //openToast()
          }
        }).catch(error => {
          console.log(error)
          // credentials didn't match
          //isError.value = true
          //errors.value = error.response.data.errors
        });        

    }

    return {
      store, page, pages, textString, searchPages, matchedItems, addPageForm, isValidated, address, city, state, zipCode, country, states, countries, storePage, selectPage, url, pageCategories, categoryId
    }
  },
  methods: {
    validateForm: function() {
      if(this.textString != null && this.textString != '') {
        if(this.address != null && this.address != '') {
          if(this.city != null && this.city != '') {
            if(this.state != null && this.state != '') {
              if(this.zipCode != null && this.zipCode != '') {
                this.isValidated = true
              } else {
                this.isValidated = false
              }
            } else {
              this.isValidated = false
            }
          } else {
            this.isValidated = false
          }
        } else {
          this.isValidated = false
        }
      } else {
        this.isValidated = false
      }
    },
    dataUp: function(p) {
      this.$emit('get-the-page', {
        id: p.id,
        name: p.name,
        slug: p.slug,
        city: p.city,
        state: p.state,
        role_id: 4,
      })
    }
  },
  watch: {
    textString: function() {
      if(this.textString != null && this.textString != '') {
        if(this.textString.length >= 2) {
          this.searchPages()
          this.validateForm()
        }
      }
    },
    address: function() {
      this.validateForm()
    },
    city: function() {
      this.validateForm()
    },
    state: function() {
      this.validateForm()
    },
    zipCode: function() {
      this.validateForm()
    },
    url: function() {
      this.validateForm()
    },
    addPageForm: function() {
      if(this.addPageForm === true) {
        this.matchedItems = []
      }
    }
  }
});
</script>