<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon style="color:#202020;" :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ formTitle }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
 
    <!--<div>
      date: {{concertDate}}<br>Time: {{ concertTime}}<br>v: {{venue}}<br>Title: {{title}}<br>t: {{tickets}}<br>g: {{genres}}<br>a: {{artists}}<br>b: {{bookingDetails}}<br>s: {{status}} {{pages}}
    </div> -->
    
    <div>
      <ion-segment scrollable value="date" v-model="view">
        <ion-segment-button value="date">
          <ion-label>Date</ion-label>
        </ion-segment-button>
        <ion-segment-button value="time" :disabled="timeDisabled">
          <ion-label>Time</ion-label>
        </ion-segment-button>
        <ion-segment-button value="venue" :disabled="venueDisabled">
          <ion-label>Venue</ion-label>
        </ion-segment-button>
        <ion-segment-button value="general" :disabled="generalDisabled">
          <ion-label>General</ion-label>
        </ion-segment-button>
        <ion-segment-button value="images" :disabled="imagesDisabled">
          <ion-label>Images</ion-label>
        </ion-segment-button>
        <ion-segment-button value="artists" :disabled="artistsDisabled">
          <ion-label>Artists</ion-label>
        </ion-segment-button>
        <ion-segment-button value="tickets" :disabled="ticketsDisabled">
          <ion-label>Tickets</ion-label>
        </ion-segment-button>
        <ion-segment-button value="genres" :disabled="genresDisabled">
          <ion-label>Genres</ion-label>
        </ion-segment-button>
        <ion-segment-button value="booking" :disabled="bookingDisabled">
          <ion-label>Booking</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>



    <!-- 
      Date
    -->
    <div v-if="view == 'date'">
      <div class="padding-top-md padding-left-md">
        <div>
          <strong>Date</strong>
        </div>
      </div>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label>Concert Date</ion-label>
        <ion-datetime value="2021-01-19" placeholder="Select Date" v-model="concertDate"></ion-datetime>
      </ion-item>
    </div>
    <!-- 
      Time
    -->
    <div v-if="view == 'time'">
      <div class="padding-top-md padding-left-md">
        <div>
          <strong>Door Time</strong>
        </div>
      </div>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label>Doors Open</ion-label>
        <ion-datetime display-format="h:mm A" minute-values="0,15,30,45" placeholder="Select Time" v-model="concertTime"></ion-datetime>
      </ion-item>   
    </div>
    <!-- 
      Venue
    -->
    <div v-if="view == 'venue'">
      <div class="padding-top-md padding-left-md">
        <div>
          <strong>Venue</strong>
        </div>
      </div>

      <div v-if="venue != null && venue != undefined" class="padding-top-sm padding-left-md">
        {{venue.name}} - {{venue.city}} {{venue.state}}
        <div class="padding-right-md padding-top-sm">
          <ion-button color="light" size="small" class="w-100" @click.prevent="venue = null">Edit Venue</ion-button>
        </div>
      </div>
      <div v-if="venue === null || venue === []">
        <div v-if="venues">
          <VenueSearch :venues="venues" @get-the-venue="getVenue" />
        </div>
      </div>

    </div>
    <!-- 
      Title
    -->
    <div v-if="view == 'general'">
      <!-- Title -->
      <div class="padding-top-md padding-left-md">
        <strong>Title</strong>
      </div>
      <!-- Title Input -->
      <div>
        <ion-item>
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-input v-model="title" placeholder="Enter a title"></ion-input>
        </ion-item>
      </div>
      <!-- Details -->
      <div class="padding-top-md padding-left-md">
        <strong>Details</strong>
      </div>
      <!-- Details Input -->
      <div>
        <TextWrapper 
          :store="store.state"
          input-type="textarea"
          :is-mentions="true" 
          :is-hashtags="true" 
          :has-button="false" 
          :set-delimeter="null"
          @input-data="getInputData" />
      </div>
      <div class="padding-top-md padding-left-md">
        <strong>Hosts</strong>
      </div>
      <div>
        <!-- Hosts -->
        <PageSearch @get-the-page="getPage" />        
      </div>
      <div>
        <!-- Next -->
        <ion-item v-if="title != null && title != ''" lines="none">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-button @click.prevent="view = 'images'">
            Upload Images
          </ion-button>
        </ion-item>
      </div>
    </div>
    <!--
      Images
    -->
    <div v-if="view == 'images'">
      <!-- Image -->
      <div v-if="imageUrl != null">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-img :src="imageUrl" />
      </div>
      <div v-else class="margin-md">
        <div style="height:140px;" class="bg-light w-100">
          &nbsp;
        </div>
      </div>
      <!-- Main image -->
      <div class="padding-left-md padding-right-md padding-bottom-md border-bottom">

        <div>
          <strong>Cover Image</strong>
          <p>This is the full size rectangular image. This image will be used as a default.</p>
        </div>
        <ion-button v-if="imageUrl === null" class="w-100" size="small" @click="takePicture(1)">
          Upload Cover Image
        </ion-button>

        <ion-button v-if="imageUrl != null" slot="start" size="small" color="danger" @click="imageUrl = null">
          <ion-icon :icon="trash"></ion-icon>
        </ion-button>  
      </div>
      <!-- Secondary image -->
      <div class="padding-left-md padding-right-md">
        <!-- Secondary Image -->
        <div v-if="secondaryImageUrl != null">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-img :src="secondaryImageUrl" />
        </div>
        <div v-else class="padding-top-md padding-bottom-md">
          <div style="height:140px;width:140px;margin:0 auto;" class="bg-light">
            &nbsp;
          </div>
        </div>
        <div>
          <strong>Thumbnail Image</strong>
          <p>This is a square image.</p>
        </div>
        <ion-button v-if="secondaryImageUrl === null" class="w-100" size="small" @click="takePicture(2)">
          Upload Thumbnail Image
        </ion-button>
        <ion-button v-if="secondaryImageUrl != null" slot="start" size="small" color="danger" @click="secondaryImageUrl = null">
          <ion-icon :icon="trash"></ion-icon>
        </ion-button>  
      </div>
      <div>
        <!-- Next -->
        <ion-item lines="none">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-button class="w-100" color="light" @click.prevent="view = 'artists'">
            Add Artists
          </ion-button>
        </ion-item>
      </div>
    </div>    
    <!--
      Artists
    -->
    <div v-if="view == 'artists'">
      <ArtistSearch @get-the-artist="getArtist" />
      <div v-if="artists">
        <div v-if="artists.length > 0">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-button @click.prevent="view = 'tickets'">
              Next
            </ion-button>
          </ion-item>
        </div>
      </div>
      <!-- Added Artists -->
      <ion-item v-if="artists.length > 0">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-list style="width:100%;">
          <ion-item v-for="(artist,index) in artists" :key="artist">
            <ion-label class="text-cap">{{artist.name}}</ion-label>
            <ion-icon slot="end" color="danger" :icon="trash" @click="removeArtist(index)" />
          </ion-item>
        </ion-list>
      </ion-item>
    </div>
    <!--
      Tickets
    -->
    <div v-if="view == 'tickets'">
      <TicketForm @get-ticket="getTicket" />
      <!-- Title -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-input v-model="ticketLink" placeholder="Ticket link"></ion-input>
      </ion-item>
      <!-- Added Tickets -->
      <ion-item v-if="tickets.length > 0">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-list style="width:100%;">
          <ion-item v-for="(ticket,index) in tickets" :key="ticket">
            <ion-label class="text-cap">
              {{ticket.type.replace('-', ' ')}} <span v-if="ticket.price != null">{{ticket.price}}</span> <span v-if="ticket.status < 1">Off Sale</span><span v-if="ticket.status === 1">On Sale</span>
            </ion-label>
            <ion-icon slot="end" color="danger" :icon="trash" @click="removeTicket(index)" />
          </ion-item>
        </ion-list>
      </ion-item>
      <div v-if="tickets">
        <div v-if="tickets.length > 0">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-button @click.prevent="view = 'genres'">
              Next
            </ion-button>
          </ion-item>
        </div>
      </div>
    </div>
    <!--
      Genres
    -->
    <div v-if="view == 'genres'">
      <GenreSearch @get-the-genre="getGenre" />
      <!-- Added Tickets -->
      <ion-item v-if="genres.length > 0">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-list style="width:100%;">
          <ion-item v-for="(genre,index) in genres" :key="genre">
            <ion-label class="text-cap">{{genre.name}}</ion-label>
            <ion-icon slot="end" color="danger" :icon="trash" @click="removeGenre(index)" />
          </ion-item>
        </ion-list>
      </ion-item>
      <div v-if="genres">
        <div v-if="genres.length > 0">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-button @click.prevent="view = 'booking'">
              Next
            </ion-button>
          </ion-item>
        </div>
      </div>
    </div>
    <!--
      Booking
    -->
    <div v-if="view == 'booking'">
     <BookingForm @get-booking="getBooking" />
    </div>

    <!--
      Preview
    -->
    <div v-if="view == 'preview'">
      <!-- Image -->
      <div v-if="imageUrl != null">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-img :src="imageUrl" />
      </div>
      <!-- Date & Title -->
      <div class="padding-top-md padding-left-md padding-right-md">
        <p>{{humanDate}}</p>
        <strong>{{title}}</strong>
      </div>
      <!-- Tickets -->
      <div v-if="tickets.length > 0" class="padding-top-md padding-left-md padding-right-md">
        <strong>Tickets</strong>
 
        <!-- bug fix for safari 14 -->
        <ion-list style="width:100%;">
          <ion-item v-for="(ticket,index) in tickets" :key="ticket">
            <div tabindex="0"></div>
            <ion-label class="text-cap">{{ticket.type.replace('-', ' ')}} <span v-if="ticket.price != null">${{ticket.price}}</span></ion-label>
            <ion-icon slot="end" color="danger" :icon="trash" @click="removeTicket(index)" />
          </ion-item>
        </ion-list>
     
        <!-- Ticket URL -->
        <div v-if="ticketLink != null && ticketLink != ''" class="padding-top-md padding-left-md padding-right-md">
          <ion-icon :icon="link" /> {{ticketLink}}
        </div>
      </div>
      <!-- Venue -->
      <div v-if="venue != null && venue != undefined" class="padding-top-md padding-left-md">
        <strong>Venue</strong>
        <p>{{venue.name}} - {{venue.city}} {{venue.state}}</p>
      </div>
      <!-- Artists -->
      <div v-if="artists.length > 0">
        <div class="padding-right-md-md padding-left-md">
          <strong>Artists</strong>
        </div>

     
        <ion-list style="width:100%;">
          <ion-item v-for="(artist,index) in artists" :key="artist">
            <div tabindex="0"></div>
            <ion-label class="text-cap">{{artist.name}}</ion-label>
            <ion-icon slot="end" color="danger" :icon="trash" @click="removeArtist(index)" />
          </ion-item>
        </ion-list>
     
      </div>

      <!-- Genres -->
      <div v-if="genres.length > 0">
        <ion-chip v-for="genre in genres" :key="genre">
          {{genre.name}}
        </ion-chip>
      </div>

      <!-- Details -->
      <div v-if="bodyText != null && bodyText != ''" class="padding-left-md padding-right-md">
        <strong>Details</strong>
        <div>
          {{bodyText}}
        </div>
      </div>
      <!--Booking -->
      <div v-if="bookingDetails != null && bookingDetails != ''" class="padding-top-sm padding-left-md">
        <strong>Booking Details</strong>
        <div>
          {{bookingDetails}}
        </div>
      </div>
      <div v-if="pages" class="padding-bottom-sm">
        <div class="padding-top-md padding-right-md padding-left-md">
          <strong>Hosts</strong>
        </div>
        <div v-for="page in pages" :key="page">
          <div v-if="page.role_id === 4">
            <ion-item>
              <div tabindex="0"></div>
              <ion-avatar>
                <img v-if="page.image" :src="page.image" />
                <img v-else src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
              </ion-avatar>
              <ion-label class="margin-left-sm text-capitalize">
                {{page.name}}
              </ion-label>
            </ion-item>
          </div>
        </div>
      </div>
      <!--Status -->
      <div class="padding-top-sm padding-left-md">
        Posted to: 
        <span v-if="status === 0">Not published</span>
        <span v-if="status === 1">Concert calendar</span>
        <span v-if="status === 2">Concert calendar & booking feed</span>
        <span v-if="status === 3">Booking feed</span>
      </div>
    </div>

    <div v-if="isValidated === 1 || isValidated === 2">
      <ion-item v-if="view != 'preview' && view != 'save'" lines="none">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-button color="light" class="w-100" @click.prevent="view = 'preview'">
          Preview & Save
        </ion-button>
      </ion-item>
      <ion-item v-if="view == 'preview'">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-button @click.prevent="storeConcert()">
          Save
        </ion-button>
      </ion-item>
    </div>

  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonDatetime, IonInput, IonItem, IonSegment, IonSegmentButton, IonLabel, IonList, loadingController, toastController, IonImg, IonChip, IonAvatar } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { close, trash, camera, link } from 'ionicons/icons';
import { useStore } from "vuex";
//import { format } from 'date-fns'
import apiClient from '../../services/api';
import TextWrapper from '../TextWrapper.vue';
import VenueSearch from '../VenueSearch.vue';
import ArtistSearch from '../ArtistSearch.vue';
import GenreSearch from '../GenreSearch.vue';
import PageSearch from '../PageSearch.vue';
import TicketForm from '../TicketForm.vue';
import BookingForm from '../BookingForm.vue';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
import { useRouter } from 'vue-router';
import { format } from 'date-fns'

export default defineComponent({
  name: 'ConcertForm',
  props: ['formTitle'],
  data() {
    return {
      content: 'Content',
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonDatetime, IonInput, IonItem, VenueSearch, ArtistSearch, IonSegment, IonSegmentButton, IonLabel, GenreSearch, TicketForm, BookingForm, IonList, TextWrapper, PageSearch, IonImg, IonChip, IonAvatar },
  setup() {
    const router = useRouter();
    const store = useStore();
    const step = ref(0);
    const view = ref('date');
    const concertDate = ref(null);
    const concertTime = ref(null);
    const venues = ref([]);
    const venue = ref(null);
    const title = ref(null);
    const bodyText = ref(null);
    const linkData = ref(null);
    const artists = ref([]);
    const pages = ref([]);
    const tickets = ref([]);
    const genres = ref([]);
    const bookingDetails = ref(null);
    const status = ref(1);
    const isValidated = ref(0);
    const timeout = { default: 6000 }
    const toastMessage = ref(null);
    const imageUrl = ref(null);
    const secondaryImageUrl = ref(null);
    const ticketLink = ref(null);
    const humanDate = ref(null);
    //
    const timeDisabled = ref(true);
    const venueDisabled = ref(true);
    const generalDisabled = ref(true);
    const artistsDisabled = ref(true);
    const ticketsDisabled = ref(true);
    const genresDisabled = ref(true);
    const bookingDisabled = ref(true);
    const imagesDisabled = ref(true);

    onMounted(() => {
      getVenues()
    })

    function closeModal() {
      store.commit('setDismissModal', true)    
    }

    function getVenues() {
      apiClient.get('/api/get/all-venues',
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          venues.value = response.data
        }).catch(error => {
          console.log(error)
        }); 
    }

    function removeTicket(i) {
      tickets.value.splice(i, 1)
    }

    function removeGenre(i) {
      genres.value.splice(i, 1)
    }

    function removeArtist(i) {
      artists.value.splice(i, 1)
    }

    function getInputData(e) {
      if(e) {
        bodyText.value = e.text
        linkData.value = e.linkData
      }
    }

    function validateConcert() {
      let isDate = false
      let isTime = false
      let isVenue = false
      let isTitle = false
      let isGenres = false
      let isTickets = false
      // Date
      if(concertDate.value) {
        humanDate.value = format(Date.parse(concertDate.value), 'eeee, MMMM dd yyyy | h:MM')
        isDate = true
      }
      // Time
      if(concertTime.value) {
        isTime = true
      }
      // Venue
      if(venue.value) {
        isVenue = true
      }
      // Title
      if(title.value != null && title.value != '') {
        isTitle = true
      }
      // Genres
      if(genres.value) {
        isGenres = true
      } 
      // Tickets
      if(tickets.value) {
        isTickets = true
      } 

      // Fully validated for public calendar
      if(isDate === true && isTime === true && isVenue === true && isTitle === true && isGenres === true && isTickets === true) {
        isValidated.value = 1
      } else {
        // Booking Only
        if(isDate === true && isTime === true && isVenue === true && isTitle === true) {
          isValidated.value = 1
        } 
        // Not validated
        else {
          isValidated.value = 0
        }
      }
    }

    async function takePicture(imgNum) {
      let isAvailable = true;
      if (!isAvailable) {
        // Have the user upload a file instead
        alert("No Camera Aailable");
      } else {
        // Otherwise, make the call:
        try {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
          });
          //console.log("image", image);
          // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
          if(imgNum === 1) {
            imageUrl.value = image.dataUrl;
          }
          if(imgNum === 2) {
            secondaryImageUrl.value = image.dataUrl;
          }          
          // can be set to the src of an image now
          //console.log(image);
        } catch (e) {
          console.log("error", e);
        }
      }
    }

    function storeConcert() {
      let combinedPages = []

      if(pages.value) {
        for(var i=0;i<pages.value.length;i++) {
          combinedPages.push(pages.value[i])
        }
      }
      if(artists.value) {
        for(var x=0;x<artists.value.length;x++) {
          combinedPages.push(artists.value[x])
        }
      }
      presentLoading()
      apiClient.post('/api/store/post', 
        {
          title: title.value,  
          body: bodyText.value, 
          status: status.value, 
          post_type: 'concert' ,
          image: imageUrl.value,
          category: 'concert',
          date_time: concertDate.value, 
          venue_id: venue.value.id,
          tickets: tickets.value,
          genres: genres.value,   
          booking_details: bookingDetails.value,
          pages: combinedPages,
          ticket_link: ticketLink.value,
          secondary_image: secondaryImageUrl.value,
          scene_id: venue.value.scene_id  
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            toastMessage.value = 'Your post has been created.'
            openToast()
            //isError.value = false
            //errors.value = []
            //resetForm()
            store.commit('setFeedTrigger', true);
            router.push('home')
          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'There was a problem.'
            openToast()
            //isError.value = true
            //errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
        }).catch(error => {
          console.log(error)
          // credentials didn't match
          //isError.value = true
          //errors.value = error.response.data.errors
        });      
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function resetForm() {
      title.value = null
      bodyText.value = null
      status.value = 1
      imageUrl.value = null
      secondaryImageUrl.value = null
      concertDate.value = null
      venue.value = null
      tickets.value = []
      pages.value = []
      genres.value = null
      bookingDetails.value = null
      artists.value = []
      ticketLink.value = null
    }

    return {
      close, closeModal, concertDate, concertTime, step, venues, venue, title, artists, view, timeDisabled, venueDisabled, generalDisabled, artistsDisabled, ticketsDisabled, genresDisabled, bookingDisabled, tickets, removeTicket, trash, genres, removeGenre, bookingDetails, status, isValidated, validateConcert, storeConcert, bodyText, linkData, imageUrl, getInputData, takePicture, resetForm, router, store, removeArtist, camera, ticketLink, secondaryImageUrl, pages, imagesDisabled, humanDate, link  
    }
  },
  methods: {
    getVenue: function(e) {
      if(e) {
        this.venue = e
      }
    },
    getPage: function(e) {
      if(e) {
        this.pages.push(e)
      }
    },
    getArtist: function(e) {
      if(e) {
        this.artists.push(e)
      }
      if(this.artists) {
        for (var i=0;i<this.artists.length;i++) {
          if(this.artists[i].genres) {
            for (var x=0;x<this.artists[i].genres.length;x++) {
              if(this.isGenreUnique(this.artists[i].genres[x]) === true) {
                this.genres.push(this.artists[i].genres[x])
              }
            }
          }
        }
      }
      this.validateConcert()
    },
    getTicket: function(e) {
      if(e) {
        this.tickets.push(e)
      }
      this.validateConcert()
    },
    getGenre: function(e) {
      if(e) {
        this.genres.push(e)
      }
      this.validateConcert()
    },
    getBooking: function(e) {
      if(e) {
        this.status = e.status
        this.bookingDetails = e.bookingDetails
      }
      this.validateConcert()
    },
    isGenreUnique: function(g) {
      let unique = true
      if(this.genres) {
        for(var i=0; i<this.genres.length;i++) {
          if(this.genres[i].id === g.id) {
            unique = false
          }
        }
      }
      return unique
    }
  },
  watch: {
    concertDate: function() {
      if(this.concertDate) {
        this.timeDisabled = false
        this.view = 'time'
      } else {
        this.timeDisabled = true
        this.view = 'date'
      }
      this.validateConcert()
    },
    concertTime: function() {
      if(this.concertDate) {
        this.venueDisabled = false
        this.view = 'venue'
      } else {
        this.view = 'time'
        this.venueDisabled = true
      }
      this.validateConcert()
    },
    venue: function() {
      if(this.venue != null && this.venue != []) {
        this.generalDisabled = false
        this.view = 'general'
      } else {
        this.generalDisabled = true
        this.view = 'venue'  
      }
    },
    title: function() {
      if(this.title != null && this.title != '') {
        this.artistsDisabled = false
        this.imagesDisabled = false
      } else {
        this.artistsDisabled = true
        this.imagesDisabled = true
      }
      this.validateConcert()
    },
    /*artists: function() {
      if(this.artists) {
        if(this.artists != []) {
          this.ticketsDisabled = false
        } else {
          this.ticketsDisabled = true
        }
      }
    },*/
    isValidated: function() {
      if(this.isValidated > 0) {
        this.ticketsDisabled = false
        this.genresDisabled = false
        this.bookingDisabled = false
      }
    }
    /*tickets: function() {
      if(this.tickets) {
        if(this.tickets != []) {
          this.genresDisabled = false
        } else {
          this.genresDisabled = true
        }
      }
    },
    genres: function() {
      if(this.genres) {
        if(this.genres != []) {
          this.bookingDisabled = false
        } else {
          this.bookingDisabled = true
        }
      }
    }*/
  }
});
</script>