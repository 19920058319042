<template>
  <ion-header>
    <ion-toolbar>

      <ion-buttons slot="start">
        <ion-button @click="closeModal()">
          <ion-icon style="color:#202020;" :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-buttons slot="end">
        <ion-button v-if="bodyText != null && bodyText != ''" color="primary" @click="storePost()">
          Post
        </ion-button>
        <ion-button v-else color="light">
          Post
        </ion-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>

    </ion-toolbar>
  </ion-header>
  <ion-content>

    <!-- Image -->
    <div v-if="imageUrl != null">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-img :src="imageUrl" />
    </div>

    <TextWrapper 
      :store="store.state"
      input-type="textarea"
      :is-mentions="true" 
      :is-hashtags="true" 
      :has-button="false" 
      :set-delimeter="null"
      @input-data="getInputData" />

    <div class="ion-padding">
      <ion-button v-if="imageUrl === null" slot="start" size="small" color="light" @click="takePicture()">
        <ion-icon :icon="camera"></ion-icon>
      </ion-button>
      <ion-button v-if="imageUrl != null" slot="start" size="small" color="danger" @click="imageUrl = null">
        <ion-icon :icon="trash"></ion-icon>
      </ion-button>  
    </div>

  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonImg, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { close, camera, trash } from 'ionicons/icons';
import { useStore } from "vuex";
import TextWrapper from '../TextWrapper.vue';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
import apiClient from '../../services/api'; 
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PostForm',
  props: ['title'],
  data() {
    return {
      content: 'Content',
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, TextWrapper, IonImg },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const bodyText = ref(null);
    const imageUrl = ref(null);
    const timeout = { default: 6000 }
    const toastMessage = ref(null);
    const linkData = ref([]);
    const linkDescription = ref(null);
    const linkTitle = ref(null);
    const linkImage = ref(null);
    const url = ref(null);

    function closeModal() {
      store.commit('setDismissModal', true)    
    }

    function getInputData(e) {
      if(e) {
        bodyText.value = e.text
        linkData.value = e.linkData
      }
    }

    async function takePicture() {
      let isAvailable = true;
      if (!isAvailable) {
        // Have the user upload a file instead
        alert("No Camera Aailable");
      } else {
        // Otherwise, make the call:
        try {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
          });
          console.log("image", image);
          // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
          imageUrl.value = image.dataUrl;
          // can be set to the src of an image now
          console.log(image);
        } catch (e) {
          console.log("error", e);
        }
      }
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function storePost() {
      presentLoading()
      apiClient.post('/api/store/post', 
        { 
          title: null,
          body: bodyText.value, 
          status: 1, 
          post_type: 'post' ,
          image: imageUrl.value,
          url: url.value,
          genres: authUser.value.genres,
          link_image: linkImage.value,
          link_title: linkTitle.value,
          link_description: linkDescription.value,
          external: 1
          //mentions: mentions.value,
          //hashtags: hashtags.value       
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            toastMessage.value = 'Your post has been created.'
            openToast()
            //isError.value = false
           // errors.value = []
            resetForm()
            // Refresh feed
            store.commit('setFeedTrigger', true);
            // Clear text wrapper
            store.commit('setGenericTrigger', true)
            closeModal()
            // Redirect home
            router.push('home')

          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'There was a problem.'
            openToast()
            //isError.value = true
            //errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
        }).catch(error => {
          console.log(error)
          // credentials didn't match
          //isError.value = true
          //errors.value = error.response.data.errors
        });        

    }

    function resetForm() {
      bodyText.value = null
      imageUrl.value = null
    }

    return {
      close, closeModal, store, getInputData, bodyText, camera, imageUrl, takePicture, trash, storePost, router, resetForm, linkData, linkDescription, linkImage, linkTitle, url
    }
  },
  watch: {
    bodyText: function() {
      if(this.bodyText === null || this.bodyText == '') {
        this.linkData = []
      }
    },
    linkData: {
      handler() {
        if(this.linkData) {
          this.linkTitle = this.linkData.title
          this.linkImage = this.linkData.cover_image 
          this.linkDescription = this.linkData.description 
          this.url = this.linkData.url
        }
      },
      deep: true
    }
  }
});
</script>