<template>
	<div v-if="items">

		<div id="comment-row" v-if="items.length > 0">

			<div v-for="(item, index) in items" :key="item">

				<ion-grid>
					<ion-row>
						<ion-col size="2" style="padding:0px;" class="text-left">
							<ion-avatar>
								<img v-if="item.author.profile_photo_path" :src="item.author.profile_photo_path" />
								<img v-else src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
							</ion-avatar>	
						</ion-col>
						<ion-col size="8" style="padding:0px;">

							<div class="comment-bubble">
								<!-- Image -->
								<div v-if="item.image != null && item.image != ''">
									<img :src="item.image" />
								</div>
								<!-- Text -->
								<div class="comment-bubble-author">
									{{item.author.name}}
								</div>
								<div v-if="item.body != null && item.body != ''">
									<div v-html="replaceURLs(item.body)"></div>
								</div>
							</div>
						</ion-col>
						<ion-col size="2" style="padding:0px;" class="text-right">
							<ion-button v-if="authUser.id > 0" class="like" size="small" @click.prevent="likeComment(item.id, item.has_user_liked, index)">
								<ion-icon v-if="item.has_user_liked === 1" color="danger" :icon="heart" /> 
								<ion-icon v-if="item.has_user_liked === 0" style="color:#d7d8da;" :icon="heart" /> 
							</ion-button>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="2" style="padding:0px;" class="text-left">
							&nbsp;
						</ion-col>
						<ion-col size="8" style="padding:0px;">
							<ion-item lines="none">
								<div tabindex="0"></div>
								<ion-button slot="start" class="like" size="small" @click.prevent="showLikes(item.likes)">
									<ion-icon :icon="heart" /> {{item.like_count}} 
								</ion-button>

								<ion-button v-if="authUser.id > 0" slot="start" class="like" size="small" @click="replyToComment(item.id, item.author.username)">
									<ion-icon :icon="chatbubble" /> Reply
								</ion-button>

								<ion-button v-if="authUser.id > 0" slot="end" class="more" size="small" @click="presentActionSheet(item.id, item.user_id, item.body)">
									<ion-icon :icon="ellipsisHorizontal" /> 
								</ion-button>
							</ion-item>
						</ion-col>
						<ion-col size="2" style="padding:0px;" class="text-right">
							&nbsp;
						</ion-col>
					</ion-row>
				</ion-grid>
				<!-- 
					Comment Replies 
				-->
				<div v-if="item.replies != null">
					<div v-if="item.replies.length > 0">
						<CommentReplies :replies="item.replies" :post-id="postId" :comment-id="item.id" />
					</div>
				</div>
			</div>

		</div>
		<div v-else>
			There are no comments
		</div>
	</div>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonAvatar, IonItem, actionSheetController, modalController } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from "vuex";
import { heart, chatbubble, ellipsisHorizontal } from 'ionicons/icons'
import apiClient from '../services/api';
import CommentReplies from './CommentReplies.vue';
import LikeList from './modals/LikeList.vue';

export default defineComponent( {
	components: { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonAvatar, IonItem, CommentReplies },
	name: 'Comments',
	props: ['comments', 'postId'],
	setup(props) {
		const store = useStore();
		const items = ref([]);
		const authUser = computed(() => store.state.authUser);
		const dismissCommentLikeModal = computed(() => store.state.dismissCommentLikeModal);
		const currentModal = ref(null);

		onMounted(() => {
			items.value = []
			doItems()
		})

		function doItems() {
			if(props.comments) {
				if(props.comments.length > 0) {
					for(var i=0;i<props.comments.length;i++) {
						items.value.push(props.comments[i])
					}
				}
			}

		}

		function replyToComment(id, username) {
			store.commit('setCommentReply', {
				commentId: id,
				username: username
			})
			store.commit('setCommentId', id)
		}

		function likeComment(id, hasUserLiked, i) {	
			// Update icon color
			if(hasUserLiked === 0) {
				items.value[i].has_user_liked = 1
				items.value[i].like_count = items.value[i].like_count + 1
				// Insert into item likes
				items.value[i].likes.push({"user": store.state.authUser})
			} else {
				items.value[i].has_user_liked = 0
				items.value[i].like_count = items.value[i].like_count - 1
				// Remove from item likes
				let commentLikes = []
				if(items.value[i].likes && authUser.value) {
					commentLikes = items.value[i].likes
					for(var x=0;x<commentLikes.length;x++) {
						if(commentLikes[x].user.id === authUser.value.id) {
							commentLikes.splice(x, 1)
						}
					}
				}
			}
			// Update database
			apiClient.post('/api/store/comment-like', 
				{
					post_id: props.postId, 
					comment_id: id, 
					type: 'comment'         
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Success
				if(response.data.message == 'success') {
					// item was not liked when loaded
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					// set toast / 'There was a problem.'          
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					// set toast / 'You must be signed in to like.'
				}
			}).catch(error => {
				// credentials didn't match
				console.log(error)
			}); 
		}

		function replaceURLs(message) {
			if(!message) return;

			var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
			return message.replace(urlRegex, function (url) {
				var hyperlink = url;
				if (!hyperlink.match('^https?:')) {
					hyperlink = 'http://' + hyperlink;
				}
				return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
			});
		}

		function canUserEdit(userId) {
			let isAuthorized = false
			if(authUser.value) {
				if(authUser.value.id === userId) {
					isAuthorized = true
				}
			}
			return isAuthorized
		}

		async function presentActionSheet(id, userId, commentBody) {
			let buttons = []
			buttons.push({
				text: 'Report Comment',
				//icon: settings,
				handler: () => {
					reportComment(id)
				},	
			})
			// If authorized
			if(canUserEdit(userId) === true) {
				// Can Edit 
				buttons.push({
					text: 'Edit Comment',
					//icon: settings,
					handler: () => {
						store.commit('setCommentId', id)
						store.commit('setCommentToEdit', commentBody)
					},
				})
				// Can Delete
				buttons.push({
					text: 'Delete Comment',
					//icon: settings,
					handler: () => {
						destroyComment(id)
					},
				})
			}
			// Cancel
			buttons.push({
				text: 'Cancel',
				role: 'destructive',
				//icon: close,
				handler: () => {
					console.log('Delete clicked')
				},	
			})

			const actionSheet = await actionSheetController
			.create({
				cssClass: 'explore-menu-settings',
				buttons: buttons,
			});

			return actionSheet.present();
		}

		function reportComment(id) {
			apiClient.post('/api/report/comment', 
				{
					comment_id: id,          
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data === 1) {
						store.commit('setReloadSinglePost', true)
						store.commit('setToast', true)
						store.commit('setToastMessage', 'You have reported a comment.')
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						// set toast / 'There was a problem.'          
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						// set toast / 'You must be signed in to like.'
					}
				}).catch(error => {
					// credentials didn't match
					console.log(error)
				});
		}

		function destroyComment(id) {
			apiClient.post('/api/destroy/comment', 
				{
					comment_id: id,          
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data.message === 'success') {
						store.commit('setReloadSinglePost', true)
						store.commit('setToast', true)
						store.commit('setToastMessage', 'You have deleted a comment.')
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						// set toast / 'There was a problem.'          
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						// set toast / 'You must be signed in to like.'
					}
					// Fail Auth
					if(response.data.message == 'fail') {
						// set toast / 'You must be signed in to like.'
					}
				}).catch(error => {
					// credentials didn't match
					console.log(error)
				});
		}

		async function showLikes(likes) {

			let commentLikes = []

			for(var i=0;i<likes.length;i++) {
				commentLikes.push({
					id: likes[i].id,
					name: likes[i].name,
					username: likes[i].username,
					image: likes[i].image,
					short_description: likes[i].short_description,
				})
			}

			const modal = await modalController.create({
				component: LikeList,
				componentProps: {
					title: 'Likes',
					likes: commentLikes,
					likeType: 'comment'
				},
			});

			await modal.present();
			currentModal.value = modal;
		}

		function closeModal() {
			if (currentModal.value) {
				currentModal.value.dismiss().then(() => { currentModal.value = null; });
				store.commit('setDismissCommentLikeModal', false)
			}
		}

		return {
			store, items, heart, chatbubble, ellipsisHorizontal, likeComment, replyToComment, doItems, replaceURLs, presentActionSheet, reportComment, dismissCommentLikeModal, currentModal, showLikes, closeModal, authUser
		}
	},
	methods: {

	},
	watch: {
		comments: {
			handler() {
				this.items = []
				this.doItems()
			},
			deep:true
		},
		dismissCommentLikeModal: function() {
			if(this.dismissCommentLikeModal === true) {
				this.closeModal()
			}
		}
	},


});
</script>

<style scoped>
	ion-avatar {
		width:28px;
		height:28px;
	}
	ion-grid {
		padding:0px!important;
		margin-bottom:20px;
	}
	.comment-bubble {
		font-size:14px;
		padding:10px;
		background: var(--ion-color-light);
		border-radius: 10px;
	}
	.comment-bubble-author {
		width:100%;
		font-weight: bold;
		display:flex;
	}
	.chat-icon-row {
		font-size:14px;
	}
	#comment-row ion-item {
		--min-height: 24px!important;
		--padding-start: 0px!important;
		--padding-end: 0px!important;
		--inner-padding-end:0px!important;
		padding-right:0px!important;

	}
	#comment-row ion-button {
		padding:0px;
		margin-right:0px!important;
		--background: var(--ion-color-white);
		--color: var(--ion-color-medium-shade);
		--padding-end:0rem;
		--padding-start:0rem;
		--background-activated: var(--ion-color-white);
	}
	#comment-row .like {
		margin-right:10px!important;
		margin-top:0px;
		margin-bottom:0px;
	}
	#comment-row .more {
		margin-right:0px!important;
		margin-top:0px;
		margin-bottom:0px;
	}
</style>