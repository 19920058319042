<template>
	<div v-if="post">
		<ion-card>
			<div v-if="postType != 'concert'">
				<ion-item lines="none">
					<div tabindex="0"></div>
					<ion-avatar>
						<img v-if="author.image" :src="author.image" />
						<img v-else src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
					</ion-avatar>						

					<span @click.prevent="goToProfile(authorLink)" style="cursor:pointer;margin-left:5px;font-size:12px;color:#202020;font-weight:bold;">
						{{author.name}}
					</span>					
				</ion-item>
			</div>
			<!-- Image -->
			<img v-if="image != null" :src="image" />
			<!-- Content -->
			<ion-card-content>
				<!-- 
					Link 
				-->
				<div v-if="isLink === true">
					<!-- Link Title -->
					<div v-if="linkData.title != null && linkData.title != ''" style="margin-bottom:10px">
						<ion-card-title @click.prevent="goToLink()">
							{{linkData.title}}
						</ion-card-title>
					</div>
					<!-- Link Body -->
					<div v-if="linkData.description != null && linkData.description != ''" style="margin-bottom:10px">
						{{linkData.description}}
					</div>
					<!-- Post Body -->
					<div v-if="postBody != null" style="border-top:1px solid #eeeeee;padding-top:10px;">
						<div v-html="postBody"></div>
					</div>
				</div>
				<!-- 
					No Link 
				-->
				<div v-else>
					<div v-if="postType == 'classified'">
						<div v-if="post.classified">
							<div class="text-capitalize text-sm">
								Classifieds > {{post.classified.category}} > {{post.classified.sub_category}}
							</div>
						</div>
					</div>
					<!-- Post Title -->
					<div v-if="postTitle != null && postTitle != ''" style="margin-bottom:10px">
						<ion-card-title @click.prevent="goToLink()">
							{{postTitle}}
						</ion-card-title>
					</div>
					<!--
						Concert
					-->
					<div v-if="postType == 'concert'">
						<!-- Concert -->
						<div class="text-dark padding-bottom-sm border-bottom">	
							<ion-icon :icon="calendar"></ion-icon>
							<strong>
								<span class="margin-left-sm text-capitalize text-danger">{{concertDate}}</span>
							</strong>
						</div>
						<!-- 
							Venue 
						-->
						<div v-if="post.event" class="padding-top-sm padding-bottom-sm border-bottom">
							<div class="text-dark padding-bottom-sm">
								<strong>Venue</strong>
							</div>
							<div v-if="post.event.venue">
								<div class="text-dark">
									<ion-icon :icon="location" /> {{post.event.venue.name}}
								</div>
								<div style="font-size:12px;">
									{{post.event.venue.street_address}} {{post.event.venue.city}} {{post.event.venue.state}}, {{post.event.venue.zip_code}} {{post.event.venue.country}}
								</div>
							</div>							
						</div>
						<!-- 
							Tickets 
						-->
						<div v-if="post.tickets" class="padding-top-sm padding-bottom-sm border-bottom">
							<div class="text-dark">
								<strong>Tickets</strong>
							</div>

							<ion-item v-for="ticket in post.tickets" :key="ticket">
								<div tabindex="0"></div>
								<ion-icon :icon="ticketSharp"></ion-icon>
								<ion-label class="margin-left-sm text-capitalize">{{ticket.label}}: {{ticket.price}}</ion-label>
							</ion-item>

							<!-- 
								Ticket Link 
							-->
							<div v-if="post.event">
								<div v-if="post.event.ticket_link" class="padding-top-sm">
									<ion-button class="w-100" size="small" :href="'//'+post.event.ticket_link">Buy Tickets</ion-button>
								</div>
							</div>
						</div>
						<!-- 
							Artists
						-->
						<div class="text-dark padding-top-sm padding-bottom-md border-bottom">
							<div>
								<strong>Artists</strong>
							</div>
							<div v-if="post.artists">
								<ion-item v-for="artist in post.artists" :key="artist" @click.prevent="goToProfile('/profile/'+artist.slug)">
									<div tabindex="0"></div>
									<ion-avatar>
										<img v-if="artist.image" :src="artist.image" />
										<img v-else src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
									</ion-avatar>
									<ion-label class="margin-left-sm text-capitalize">
										{{artist.name}}
									</ion-label>
								</ion-item>
							</div>
							<div v-else>
								There are no artists, yet.
							</div>
						</div>
						<!-- Post Body -->
						<div v-if="postBody != null && postBody != ''" class="text-dark padding-top-sm padding-bottom-md border-bottom">
							<div>
								<strong>Details</strong>
							</div>
							<div v-html="postBody"></div>
						</div>
						<!-- RSVP -->
						<div class="text-dark padding-top-sm padding-bottom-sm border-bottom">
							<div>
								<strong>RSVP</strong>
							</div>
							<div v-if="authUser">
								<ion-row v-if="authUser.id > 0">
									<ion-col size="6">
										<ion-button :color="interestedColor" size="small" class="w-100" @click.prevent="rsvp(2)">
											I'm Interested
										</ion-button>
									</ion-col>
									<ion-col size="6">
										<ion-button :color="goingColor" size="small" class="w-100" @click.prevent="rsvp(1)">
											I'm Going
										</ion-button>
									</ion-col>
								</ion-row>

							</div>
							<div>
								<ion-button color="light" size="small" class="w-100" @click.prevent="openModal()">
									Who's Going?
								</ion-button>
							</div>
						</div>
						<!-- Genres -->
						<div v-if="post.genres" class="text-dark padding-top-sm padding-bottom-sm border-bottom">
							<div>
								<strong>Genres</strong>
							</div>
							<div>
								<ion-chip v-for="genre in post.genres" :key="genre" class="text-capitalize">
									{{genre.name}}
								</ion-chip>
							</div>
						</div>
						<!-- Genres -->
						<div v-if="post.pages" class="text-dark padding-top-sm padding-bottom-sm">
							<div>
								<strong>Hosts</strong>
							</div>
							<div v-if="post.pages" class="padding-bottom-sm">
								<div v-for="page in post.pages" :key="page">
									<div v-if="page.role_id === 4">
										<ion-item>
											<div tabindex="0"></div>
											<ion-avatar>
												<img v-if="page.image" :src="page.image" />
												<img v-else src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
											</ion-avatar>
											<ion-label class="margin-left-sm text-capitalize">
												{{page.name}}
											</ion-label>
										</ion-item>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--
						Concert
					-->
					<div v-if="postType == 'classified'">
						<div v-if="post.classified">
							<div v-if="post.classified.category == 'for-sale'">
								<ion-chip>
									{{post.product.make}}
								</ion-chip>
								<ion-chip>
									<span class="text-small">{{post.product.model}}</span>
								</ion-chip>
							</div>
						</div>
					</div>
					<!-- Post Body -->
					<div v-if="postType != 'concert'">
						<!-- Post Body -->
						<div v-if="postBody">
							<div v-html="postBody"></div>
						</div>
					</div>
					<div v-if="postType == 'classified'" class="padding-top-sm">
						<div v-if="post.classified">
							<div v-if="post.classified.price">
								{{post.classified.price}}
							</div>
						</div>
					</div>
				</div>
				<!-- 
					Post Bottom 
				-->
				
				<div v-if="postSlug && post.id > 0">
					<PostBottom 
						:slug="postSlug" 
						:post-id="post.id" 
						:display="display" 
						:comment-count="post.comment_count"
						:post-author-id="post.user_id" 
					/>
				</div>	

				<!-- Comments -->
				<div v-if="display == 'page'" style="padding-top:30px;padding-bottom:60px;">
					<Comments :comments="post.comments" :post-id="post.id" />
				</div>
			</ion-card-content>
		</ion-card>
	</div>
</template>

<script>
import { IonCard, IonCardContent, IonCardTitle, IonAvatar, IonItem, IonChip, IonRow, IonCol, IonButton, loadingController, toastController, modalController, IonIcon, IonLabel } from '@ionic/vue';
import { format } from 'date-fns'
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { location, ticketSharp, calendar } from 'ionicons/icons';
import PostBottom from './PostBottom.vue';
import Comments from './Comments.vue';
import Attendees from './modals/Attendees.vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

export default defineComponent( {
	components: { IonCard, IonCardContent, IonCardTitle, IonAvatar, PostBottom, Comments, IonItem, IonChip, IonRow, IonCol, IonButton, IonIcon, IonLabel },
	name: 'ArticleCard',
	props: ['post', 'isAuth', 'display'],
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const isLink = ref(false);
		const linkData = ref([]);
		const externalLink = ref(false);
		const postLink = ref(null);
		const postType = ref('post');
		const postTitle = ref(null);
		const postBody = ref(null);
		const postId = ref(0);
		const authUser = computed(() => store.state.authUser);
		const image = ref(null);
		const postSlug = ref(null);
		const author = ref([]);
		const authorLink = ref(null);
		const likeStatus = ref(0);
		const timeout = { default: 6000 }
		const toastMessage = ref(null);	
		const dismissModal = computed(() => store.state.dismissModal);
		const currentModal = ref(null);
		const interestedColor = ref('light');
		const goingColor = ref('light');
		const concertDate = ref(null);

		onMounted(() => {
			doLink()
			doPages()
			doPost()
		})

		function doLink() {
			if(props.post) {
				if(props.post.link_id > 0) {
					if(props.post.link_data != null) {
						isLink.value = true
						linkData.value = props.post.link_data
						postLink.value = props.post.link_data.url
						// Link Target
						if(props.post.link_data.external === 1) {
							externalLink.value = true
						}
						// Image
						if(props.post.link_data.cover_image != null && props.post.link_data.cover_image != '') {
							image.value = props.post.link_data.cover_image
						}
					}
				}
			}
		}

		function doPages() {
			if(props.post) {
				if(props.post.pages) {
					let pageAuthors = []
					for(var i=0;i<props.post.pages.length;i++) {
						if(props.post.pages[i].status === 1) {
							if(props.post.pages[i].role_id == 1) {
								pageAuthors.push(props.post.pages[i])
							}							
						}
						//console.log(props.post.pages[i])
					}
					if(pageAuthors.length > 0) {
						author.value = pageAuthors[0]
						authorLink.value = '/profile/' + author.value.slug
					}
					//console.log(pageAuthors)
					
				}
			}
		}

		function doPost() {
			if(author.value.length < 1) {
				if(props.post.author) {
					author.value = {
						id: props.post.author.id,
						name: props.post.author.name,
						slug: props.post.author.username,
						image: props.post.author.profile_photo_path,
					}
					authorLink.value = '/profile/' + author.value.slug					
				}
			}
			if(props.post.filename != null && props.post.filename != '') {
				if(linkData.value.length < 1) {
					image.value = props.post.filename
				} 
			}
			if(props.post.attendance === 1) {
				goingColor.value = 'secondary'
			}
			if(props.post.attendance === 2) {
				interestedColor.value = 'secondary'
			}
			//if(externalLink.value === false) {
			postId.value = props.post.id
			postSlug.value = props.post.slug
			postType.value = props.post.post_type
			//}
			if(props.post.title != null && props.post.title != '') {
				postTitle.value = props.post.title
			}
			if(props.post.body != null && props.post.body != '') {
				convertUrls(props.post.body)
			}
			doPostLikes() 
			if(props.post.post_type == 'concert') {
				if(props.post.event) {
					concertDate.value = format(Date.parse(props.post.event.date_time), 'eeee, MMMM dd yyyy | h:MM')
				}
			}
		}

		function doPostLikes() {
			// Make sure post is no empty
			if(props.post) {
				let postLikes = []
				// Make sure there are post likes
				if(props.post.likes) {
					
					let likes = []

					// Loop through likes and build new ojbect
					for(var i=0;i<props.post.likes.length;i++) {
						likes.push(props.post.likes[i])
					}

					postLikes = {
						post_id: props.post.id,
						likes: likes
					}
				}
				store.commit('setPostLike', postLikes)
			}
		}

		async function goToLink() {
			if(externalLink.value === true) {

				//window.location = postLink.value
				await Browser.open({ url: postLink.value });
			} else {
				router.push('/post/' + postSlug.value)
			}
		}

		function rsvp(val) {
			presentLoading()
			apiClient.post('/api/store/rsvp', 
				{ 
					status: val,
					post_id: postId.value    
				},
				{
				headers: {
				'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have responded to this concert.'
					openToast()
					if(val == 1) {
						goingColor.value = 'primary'
						interestedColor.value = 'light'
					}
					if(val == 2) {
						interestedColor.value = 'primary'
						goingColor.value = 'light'
					}
				}
				// Success
				if(response.data.message == 'success-delete') {
					toastMessage.value = 'You have responded to this concert.'
					openToast()
					if(val == 1) {
						goingColor.value = 'light'
					}
					if(val == 2) {
						interestedColor.value = 'light'
					}
				}
				// Fail Validation
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()           
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You must be signed in.'
					openToast()
				}
			}).catch(error => {
				console.log(error)
			}); 
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

						}
					}
				]
			})
			return toast.present();
		}

		async function openModal() {
			let modalData = []
			// Post

			modalData = {
				component: Attendees,
				componentProps: {
					postId: postId.value,
				},
			}

			const modal = await modalController.create(modalData);

			await modal.present();
			currentModal.value = modal;
		}

		function closeModal() {
			if (currentModal.value) {
				currentModal.value.dismiss().then(() => { currentModal.value = null; });
				store.commit('setDismissModal', false)
			}
		}

		function goToProfile(path) {
			router.push(path)
		}

		function convertUrls(textString) {
			var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

			if(textString.match(urlRegex)) {
				let tempUrls = textString.match(urlRegex)

				//console.log('test' + tempUrls)

				for(var i=0; i<tempUrls.length;i++) {
					// search for url and replace with clickable link
					//textString.replace(tempUrls[i], '<a href="'+tempUrls[i]+'" target="_blank">'+tempUrls[i]+'</a>')
					//let strToReplace = tempUrls[i]
					textString = textString.replace(tempUrls[i], '<a href="'+tempUrls[i]+'" target="_blank" style="text-decoration:none;">'+tempUrls[i]+'</a>')

				}

				
			}

			

			postBody.value = textString
		}

		return {
			router, store, isLink, linkData, externalLink, image, author, postLink, goToLink, postSlug, postBody, postTitle, authorLink, likeStatus, authUser, postType, rsvp, postId, closeModal, openModal, currentModal, dismissModal, interestedColor, goingColor, location, ticketSharp, concertDate, calendar, doLink, doPages, doPost, goToProfile
		}
	},
	watch: {
		dismissModal: function() {
			if(this.dismissModal === true) {
				this.closeModal()
			}
		},
		post: {
			handler() {
				this.doLink()
				this.doPages()
				this.doPost()
			},
			deep:true
		}
	}
});
</script>

<style scoped>
	ion-avatar {
		width:28px;
		height:28px;
	}
	ion-card-title {
		font-size:20px!important;
	}
	ion-card {
		margin-top:0px;
		margin-bottom:10px;
		margin-left:0px;
		margin-right:0px;
		border-radius:0px;
	}
</style>