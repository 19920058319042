<template>
  <ion-page>
    <Header page="scene" :scene-name="sceneName" />

    <!-- Page Content -->
    <ion-content :fullscreen="true">
      <div id="mobile-segment">
        <!-- Default Segment -->
        <ion-segment scrollable value="feed" v-model="view">
          <ion-segment-button value="feed">
            <ion-label>Feed</ion-label>
          </ion-segment-button>
          <ion-segment-button value="concerts">
            <ion-label>Concerts</ion-label>
          </ion-segment-button>
          <ion-segment-button value="artists">
            <ion-label>Artists</ion-label>
          </ion-segment-button>
          <ion-segment-button value="people">
            <ion-label>People</ion-label>
          </ion-segment-button>
          <ion-segment-button value="venues">
            <ion-label>Venues</ion-label>
          </ion-segment-button>
          <ion-segment-button value="pages">
            <ion-label>Pages</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>

      <div>
        <!-- Feed -->
        <div v-if="view == 'feed' && sceneId > 0">
          <PostFeed post-type="feed" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'concerts' && sceneId > 0">
          <PostFeed post-type="concerts" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'artists' && sceneId > 0">
          <ProfileAvatars profile-type="artist" :limit="0" view="full" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'people' && sceneId > 0">
          <ProfileAvatars profile-type="user" :limit="0" view="full" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'venues' && sceneId > 0">
          <ProfileAvatars profile-type="venue" :limit="0" view="full" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'pages' && sceneId > 0">
          <ProfileAvatars profile-type="business" :limit="0" view="full" :scene-id="sceneId" />
        </div>
      </div>
    </ion-content>

    <!-- put the tabs in ion-footer!!! -->
    <ion-footer v-if="device != 'web'">
      <Tabs page="scene" />
    </ion-footer>
    <!-- put the tabs in ion-footer!!! -->
    
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import { search, chevronBack } from 'ionicons/icons';
import { defineComponent, ref, onMounted } from 'vue';
import ProfileAvatars from '../../components/ProfileAvatars.vue';
import doAuth from '../../services/auth';
import Tabs from '../../components/Tabs.vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../../services/api';
import Header from '../../components/Header.vue';
import { isPlatform } from '@ionic/vue';
import PostFeed from '../../components/PostFeed.vue';

export default defineComponent({
  name: 'SceneSingle',
  components: {
    IonPage, IonContent, IonFooter, IonSegment, IonSegmentButton, IonLabel, ProfileAvatars, Tabs, Header, PostFeed
  }, 
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const view = ref('feed');
    const slug = ref(0);
    const scene = ref([]);
    const sceneId = ref(0);
    const sceneName = ref(null);
    const device = ref('web');

    onMounted(() => {
      // App
      if(isPlatform('ios') === true || isPlatform('android') === true)  {
        device.value = 'mobile-app'
      } 
      // Web
      else {
        device.value = 'web'
      }
      slug.value = router.currentRoute._rawValue.params.slug
      getScene()
    })

    function getScene() {
      apiClient.get('/api/get/scene-by-slug/'+slug.value,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          scene.value = response.data
          if(scene.value) {
            sceneId.value = scene.value.id
            sceneName.value = scene.value.name + ', ' + scene.value.state
          }
        }).catch(error => {
          console.log(error)
        });  
      
    }

    function updateRoute(r) {
      router.push(r)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      search, chevronBack, updateRoute, router, view, slug, goBack, scene, sceneId, sceneName, device
    }
  }

});
</script>

<style scoped>
  ion-icon {
    font-size:16px;
  }

  #feed-type ion-segment-button {

    --indicator-box-shadow: transparent!important;
    margin-right: 5px;
    --background: var(--ion-color-dark-tint);
    --color:white;
    --background-checked: var(--ion-color-secondary);
    --color-checked: black;
    --indicator-color : transparent!important; /* this solution */

  }
  ion-segment {
    border-radius:0px!important;
  }
</style>
