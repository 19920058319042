<template>
  <div>
    <!-- Ticket Type -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-label position="stacked">Where is this show visible?</ion-label>
      <ion-select placeholder="Select One" v-model="status">
        <ion-select-option :value="2">Concert & booking feed</ion-select-option>
        <ion-select-option :value="1">Concert feed only</ion-select-option>
        <ion-select-option :value="3">Booking feed only</ion-select-option>
        <ion-select-option :value="0">Not published</ion-select-option>
      </ion-select>
    </ion-item>
    <!-- Description -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-textarea v-model="bookingDetails" placeholder="Booking details"></ion-textarea>
    </ion-item>
  </div>
</template>

<script>
import { IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'BookingForm',
  components: {
    IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea
  },
  props: ['stat', 'details'],
  setup(props) {
    const router = useRouter();
    const status = ref(2);
    const bookingDetails = ref(null);

    onMounted(() => {
      if(props.stat) {
        status.value = props.stat
      }
      if(props.details) {
        bookingDetails.value = props.details
      }
    })

    return {
      router, status, bookingDetails
    }
  },
  methods: {
    dataUp: function () {
      var bookingData = {
        status: this.status,
        bookingDetails: this.bookingDetails,
      }
      this.$emit('get-booking', bookingData)
    }
  },
  watch: {
    bookingDetails: function() {
      this.dataUp()
    },
    status: function() {
      this.dataUp()
    }
  }
});
</script>

<style scoped>

</style>