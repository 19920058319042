<template>
	<div v-if="filteredPostLikes" id="post-bottom">
		<div style="border-bottom:1px solid #d7d8da;">
			<ion-item lines="none">
				<div tabindex="0"></div>
				<ion-button slot="start" @click="showLikes()">
					<span v-if="likeCount != 1">{{likeCount}} Likes</span>
					<span v-else>{{likeCount}} Like</span>
				</ion-button>
				<ion-button slot="end" @click="updateRoute('/post/'+slug)">
					<span v-if="commentCount != 1">{{commentCount}} Comments</span>
					<span v-else>{{commentCount}} Comment</span>
				</ion-button>
			</ion-item>
		</div>
		<div v-if="slug && postId > 0 && authUser" style="padding-top:3px;">
			<ion-item v-if="authUser.id > 0" lines="none">
				<div tabindex="0"></div>
				<ion-button slot="start" class="like" size="small" @click="likePost()">
					<ion-icon v-if="userHasLiked === true" color="danger" :icon="heart" /> 
					<ion-icon v-if="userHasLiked === false" color="medium" :icon="heart" /> 
					<span v-if="userHasLiked === true" class="text-danger"> Like</span>
					<span v-if="userHasLiked === false" class="text-medium"> Like</span>
				</ion-button>

				<ion-button v-if="display === 'feed'" slot="start" class="like" size="small" @click="updateRoute('/post/'+slug)">
					<ion-icon :icon="chatbubble" /> Comment
				</ion-button>

				<ion-button slot="end" class="more" size="small" @click="presentActionSheet()">
					<ion-icon :icon="ellipsisHorizontal" /> 
				</ion-button>
			</ion-item>
		</div>
	</div>
</template>
<script>
import { IonItem, IonButton, IonIcon, actionSheetController, modalController } from '@ionic/vue';
import { defineComponent, computed, ref } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { heart, chatbubble, ellipsisHorizontal } from 'ionicons/icons'
import apiClient from '../services/api';
//import CommentReplies from './CommentReplies.vue';
import LikeList from './modals/LikeList.vue';

export default defineComponent( {
	name: 'PostBottom',
	props: ['postId', 'slug', 'postAuthorId', 'display', 'commentCount'],
	components: {
		IonItem, IonButton, IonIcon
	},
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const storedPostLikes = computed(() => store.state.storedPostLikes);
		const filteredPostLikes = computed(() => {
			let storedLikes = []
			if(store.state.storedPostLikes) {
				for(var i=0;i<store.state.storedPostLikes.length;i++) {
					if(store.state.storedPostLikes[i].post_id === props.postId) {
						storedLikes = store.state.storedPostLikes[i]
						doLikeCount(store.state.storedPostLikes[i].likes)
					}
				}
			}	
			
			isUserInLikes(storedLikes)
			return storedLikes
		});
		const authUser = computed(() => store.state.authUser);
		const userHasLiked = ref(false);
		const likeCount = ref(0);
		const dismissPostLikeModal = computed(() => store.state.dismissPostLikeModal);
		const currentModal = ref(null);

		function doLikeCount(storedLikes) {
			if(storedLikes) {
				likeCount.value = storedLikes.length
			}
		}

		function isUserInLikes(storedLikes) {
			userHasLiked.value = false
			if(storedLikes && authUser.value) {
				if(storedLikes.likes) {
					for(var i=0;i<storedLikes.likes.length;i++) {
						if(storedLikes.likes[i].id === authUser.value.id) {
							userHasLiked.value = true
						}
					}
				}				
			}
		}

		function updateRoute(val) {
			router.push(val)
		}

		function likePost() {
			apiClient.post('/api/store/post-like', 
				{
					post_id: props.postId, 
					comment_id: 0, 
					type: 'post'         
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data.message == 'success') {
						doLike(props.postId)
					}
					// Success Delete
					if(response.data.message == 'success-delete') {
						doUnlike(props.postId)
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						// set toast / 'There was a problem.'          
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						// set toast / 'You must be signed in to like.'
					}
				}).catch(error => {
					// credentials didn't match
					console.log(error)
				});
		}

		function doLike(id) {
			if(storedPostLikes.value && authUser.value) {

				for(var i=0;i<storedPostLikes.value.length;i++) {

					if(storedPostLikes.value[i].post_id === id) {
						storedPostLikes.value[i].likes.push({
							id: authUser.value.id,
							name: authUser.value.name,
							username: authUser.value.username,
							image: authUser.value.profile_photo_path,
							short_description: authUser.value.profile.short_description,
						})
						
					}
				}
				likeCount.value = likeCount.value + 1
			}
		}

		function doUnlike(id) {
			if(storedPostLikes.value && authUser.value) {

				for(var i=0;i<storedPostLikes.value.length;i++) {
					if(storedPostLikes.value[i].post_id === id) {

						// goo through likes and find index of user id
						for (var x=0;x<storedPostLikes.value[i].likes.length;x++) {
							if(storedPostLikes.value[i].likes[x].id === authUser.value.id) {
								storedPostLikes.value[i].likes.splice(x,1)
								if(likeCount.value > 0) {
									likeCount.value = likeCount.value - 1
								}
							}
						}

					}
				}
			}
		}

		async function presentActionSheet() {
			let buttons = []

			// Feed
			if(props.display != 'page') {
				buttons.push({
					text: 'View Post',
					//icon: settings,
					handler: () => {
						router.push('/post/' + props.slug)
					}
				})
			}
			// Copy
			buttons.push({
				text: 'Copy Link',
				//icon: settings,
				handler: () => {
					var url = document.body.appendChild(document.createElement("input"));
					url.value = 'https://app.artistwave.com/post/' + props.slug;
					url.select();
					document.execCommand('copy');
				}
			})

			// Authenticated
			if(authUser.value) {
				// Report
				buttons.push({
					text: 'Report Post',
					//icon: settings,
					handler: () => {
						reportPost()
					},
				})

				if(canUserEdit() === true) {
					// Can edit
					buttons.push({
						text: 'Edit Post',
						//icon: settings,
						handler: () => {
							router.push('/edit-post/'+props.slug)
						},
					})
					//Can Delete
					buttons.push({
						text: 'Delete Post',
						//icon: settings,
						handler: () => {
							destroyPost()
						},
					})
				}
			}
			//Cancel
			buttons.push({
				text: 'Cancel',
				role: 'destructive',
				//icon: close,
				handler: () => {
					console.log('Delete clicked')
				},
			})

			const actionSheet = await actionSheetController

			.create({
				cssClass: 'explore-menu-settings',
				buttons: buttons,
			});

			return actionSheet.present();
		}

		function canUserEdit() {
			let isAuthorized = false
			if(authUser.value) {
				if(props.postAuthorId) {
					if(authUser.value.id === props.postAuthorId) {
						isAuthorized = true
					}
				}
			}
			return isAuthorized
		}

		function destroyPost() {
			apiClient.get('/api/destroy/post/'+props.postId, 
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data.message == 'success') {
						console.log(response.data)
						// set toast
						// refresh feed
						if(props.display == 'page') {
							window.location = '/home'
						} else {
							store.commit('setFeedTrigger', true)
						}
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						// set toast / 'There was a problem.'          
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						// set toast / 'You must be signed in to like.'
					}
				}).catch(error => {
					// credentials didn't match
					console.log(error)
				});	
		}

		function reportPost() {
			apiClient.post('/api/report/post', 
				{
					post_id: props.postId,          
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data === 1) {
						console.log(response.data)
						// set toast
						// refresh feed
						if(props.display == 'feed') {
							store.commit('setFeedTrigger', true)
						} else {
							store.commit('setReloadSinglePost', true)
						}
						store.commit('setToast', true)
						store.commit('setToastMessage', 'You have reported a post.')
						window.location = '/home'
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						// set toast / 'There was a problem.'          
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						// set toast / 'You must be signed in to like.'
					}
				}).catch(error => {
					// credentials didn't match
					console.log(error)
				});
		}

		async function showLikes() {
			const modal = await modalController.create({
				component: LikeList,
				componentProps: {
					title: 'Likes',
					likes: filteredPostLikes.value.likes,
					likeType: 'post'
				},
			});

			await modal.present();
			currentModal.value = modal;
		}

		function closeModal() {
			if (currentModal.value) {
				currentModal.value.dismiss().then(() => { currentModal.value = null; });
				store.commit('setDismissPostLikeModal', false)
			}
		}

		return {
			storedPostLikes, filteredPostLikes, likeCount, likePost, updateRoute, router, authUser, userHasLiked, doLike, presentActionSheet, reportPost, showLikes, closeModal, heart, chatbubble, ellipsisHorizontal, dismissPostLikeModal, canUserEdit
		}
	},
	watch: {
		dismissPostLikeModal: function() {
			if(this.dismissPostLikeModal === true) {
				this.closeModal()
			}
		},
	}

})
</script>
<style scoped>
	#post-bottom ion-item {
		--min-height: 24px!important;
		--padding-start: 0px!important;
		--padding-end: 0px!important;
		--inner-padding-end:0px!important;
		padding-right:0px!important;

	}
	#post-bottom ion-button {
		padding:0px;
		margin-right:0px!important;
		--background: var(--ion-color-white);
		--color: var(--ion-color-medium-shade);
		--padding-end:0rem;
		--padding-start:0rem;
	}
	#post-bottom .like {
		margin-right:10px!important;
		margin-top:0px;
		margin-bottom:0px;
	}
	#post-bottom .more {
		margin-right:0px!important;
		margin-top:0px;
		margin-bottom:0px;
	}
</style>
