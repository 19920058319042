<template>
  <div>
    <!-- Ticket Type -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-label position="stacked">Ticket Type</ion-label>
      <ion-select placeholder="Select One" v-model="ticketType">
        <ion-select-option value="general-admission">General Admission</ion-select-option>
        <ion-select-option value="advance">Advance</ion-select-option>
        <ion-select-option value="dos">Day of show</ion-select-option>
        <ion-select-option value="vip">VIP</ion-select-option>
        <ion-select-option value="free">Free</ion-select-option>
        <ion-select-option value="other">Other</ion-select-option>
      </ion-select>
    </ion-item>
    <!-- Ticket Status -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-label position="stacked">Status</ion-label>
      <ion-select placeholder="Select One" v-model="ticketStatus">
        <ion-select-option :value="0">Off Sale</ion-select-option>
        <ion-select-option :value="1">On Sale</ion-select-option>
      </ion-select>
    </ion-item>
    <!-- Description -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-textarea v-model="description" placeholder="Description..."></ion-textarea>
    </ion-item>
    <!-- Price -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-input v-model="price" type="text" placeholder="Price"></ion-input>
    </ion-item>
    <!-- Actions -->
    <ion-item lines="none">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-button slot="end" @click="dataUp()">
        Add Ticket
      </ion-button>
    </ion-item>
  </div>
</template>

<script>
import { IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonButton, IonInput } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TicketForm',
  components: {
    IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonButton, IonInput
  },
  setup() {
    const router = useRouter();
    const ticketType = ref('general-admission');
    const description = ref(null);
    const price = ref(null);
    const ticketStatus = ref(0);

    return {
      ticketType, description, price, router, ticketStatus
    }
  },
  methods: {
    dataUp: function () {
      var ticketData = {
        type: this.ticketType,
        description: this.description,
        price: this.price,
        status: this.ticketStatus
      }
      this.$emit('get-ticket', ticketData)
      this.ticketType = 'general-admission'
      this.description = null
      this.price = null
      this.ticketStatus = 0
    }
  }
});
</script>

<style scoped>

</style>