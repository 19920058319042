<template>
  <!-- 
    App Header 
  -->
  <ion-header v-if="device != 'web'" id="app-header" :translucent="true">
    <ion-toolbar class="text-center">
      <ion-buttons slot="start">
        <ion-button v-if="leftIcon == 'options'" @click.prevent="setOpen(true)">
          <ion-icon :icon="settings"></ion-icon>
        </ion-button>
        <ion-button v-if="leftIcon == 'back'" @click.prevent="goBack()">
          <ion-icon :icon="chevronBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button v-if="page != 'scenes' && page != 'profile' && page != 'post-single'" @click.prevent="updateRoute('/profile-search')">
          <ion-icon :icon="search"></ion-icon>
        </ion-button>
        <ion-button v-if="page == 'profile' || page == 'post-single'" @click.prevent="updateRoute('/profile-search')">
          <ion-icon :icon="ellipsisHorizontal"></ion-icon>
        </ion-button>
      </ion-buttons>

      <LogoMark v-if="page != 'scene' && page != 'profile'" color="light" />
      <ion-title v-if="page == 'scene'">
        {{sceneName}}
      </ion-title>
      <ion-title v-if="page == 'profile' && slug">
        {{slug}}
      </ion-title>

    </ion-toolbar>
  </ion-header> 
  <!-- 
    Web Header 
  -->
  <ion-header v-else id="web-header" :translucent="true">
    <ion-toolbar id="web-nav" class="max-width">

      <ion-grid>
        <ion-row>
          <ion-col size="2" style="padding-top:13px;">
            <LogoMark color="light" />
          </ion-col>
          <ion-col size="10" class="text-right">
            <ion-button color="light" @click="updateRoute('/')">
              Home
            </ion-button>
            <ion-button color="light" @click="updateRoute('/concerts')">
              Concerts
            </ion-button>
            <ion-button color="light" @click="updateRoute('/artists')">
              Artists
            </ion-button>
            <ion-button color="light" @click="updateRoute('/people')">
              People
            </ion-button>
            <ion-button color="light" @click="updateRoute('/venues')">
              Venues
            </ion-button>
            <ion-button color="light" @click="updateRoute('/pages')">
              Pages
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-toolbar>
    <!-- 
      Mobile
    -->
    <ion-toolbar id="mobile-web-nav" style="width:100%;" class="text-center">
      <ion-buttons slot="start">
        <ion-button v-if="leftIcon == 'options'" @click.prevent="setOpen(true)">
          <ion-icon :icon="settings"></ion-icon>
        </ion-button>
        <ion-button v-if="leftIcon == 'back'" @click.prevent="goBack()">
          <ion-icon :icon="chevronBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click.prevent="updateRoute('/profile-search')">
          <ion-icon :icon="search"></ion-icon>
        </ion-button>
      </ion-buttons>

      <LogoMark v-if="page != 'scene' && page != 'profile'" color="light" />
      <ion-title v-if="page == 'scene'">
        {{sceneName}}
      </ion-title>
      <ion-title v-if="page == 'profile' && slug">
        {{slug}}
      </ion-title>
    </ion-toolbar>

  </ion-header> 

  <!-- put the tabs in ion-footer!!! -->
  <ion-action-sheet
    :is-open="isOpenRef"
    css-class="buttons"
    :buttons="buttons"
    @onDidDismiss="setOpen(false)"
  >
  </ion-action-sheet>

</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { IonActionSheet, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonGrid, IonRow, IonCol, IonTitle } from '@ionic/vue';
import { search, options, menu, mail, arrowBack, chevronBack, ellipsisVertical, settings, ellipsisHorizontal } from 'ionicons/icons';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { isPlatform } from '@ionic/vue';
import LogoMark from '../components/LogoMark.vue';

export default {
  props: ['page', 'username', 'hashtag', 'slug', 'sceneName'],
  name: 'Header',
  components: {
    IonActionSheet, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonGrid, IonRow, IonCol, LogoMark, IonTitle
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const authToken = computed(() => store.state.authToken);
    const appId = computed(() => store.state.appId);
    const userCollection = ref([]);
    const device = ref('web');
    //this.username = store.state.authUser.username
    const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    const leftIcon = ref('options');
    const buttons = [
      {
        text: 'Change Scene',
        handler: () => {
          router.push('/scenes')
        },
      },
      {
        text: 'Manage Genres',
        handler: () => {
          router.push('/profile-genres')
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          //
        },
      }
    ];

    onMounted(() => {
      if(props.page == 'scene' || props.page == 'scenes' || props.page == 'profile' || props.page == 'post-single') {
        leftIcon.value = 'back'
      } else {
        leftIcon.value = 'options'
      }
      // App
      if(isPlatform('ios') === true || isPlatform('android') === true)  {
        device.value = 'mobile-app'
      } 
      // Web
      else {
        device.value = 'web'
      }
      getUserCollection()
    })

    function updateRoute(r) {
      router.push(r)
    }

    function getUserCollection() {
      apiClient.get('/api/get/profile/collection',
        {
          headers: {
            'Authorization':  `Bearer ${authToken.value}`
          }
        }).then(response => {
          //userCollection.value = response.data
          for(var i=0;i<response.data.length;i++) {
            var location = null
            if(response.data[i].location != null && response.data[i].location != '') {
              location = response.data[i].location
            }

            userCollection.value.push({
              id: response.data[i].id,
              name: response.data[i].name,
              username: response.data[i].username,
              profilePhoto: response.data[i].profile_photo,
              location: location,
              profile_type: response.data[i].profile_type
            })
          }
        }).catch(error => {
          console.log(error)
        });        
    }

    function goBack() {
      router.go(-1)
    }

    return {
      search, options, menu, mail, arrowBack, router, updateRoute, store, appId, userCollection, getUserCollection, chevronBack, ellipsisVertical, device, isOpenRef, setOpen, buttons, goBack, leftIcon, settings, ellipsisHorizontal
    }
  },
  methods: {
    routeData: function(e) {
      this.updateRoute(e.path)
      console.log('parent' +e.path)
    }
  },
  watch: {
    page: function() {
      if(this.page == 'scene' || this.page == 'scenes') {
        this.leftIcon = 'back'
      } else {
        this.leftIcon = 'options'
      }
    }
  }
};
</script>
<style scoped>
  ion-icon {
    color:#000000;
    font-size:20px;
  }

  ion-button {
    --box-shadow: none!important;
  }
</style>
