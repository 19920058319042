<template>
  <div class="footer-tabs">
    <div class="footer-container">
      <ion-row>
        <ion-col>
          <div class="ion-text-center" style="cursor:pointer;" @click.prevent="changeView('home')">
            <ion-icon :icon="home" color="dark"></ion-icon><br>
            <ion-label color="dark" class="small-text">HOME</ion-label>
          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-center" style="cursor:pointer;" @click.prevent="changeView('explore')">
            <ion-icon :icon="compass" color="dark"></ion-icon><br>
            <ion-label color="dark" class="small-text">EXPLORE</ion-label>
          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-center" style="cursor:pointer;" @click.prevent="changeView('create')">
            <ion-icon :icon="add" color="dark"></ion-icon><br>
            <ion-label color="dark" class="small-text">CREATE</ion-label>
          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-center" style="cursor:pointer;" @click.prevent="changeView('notifications')">
            <ion-icon :icon="notifications" color="dark"></ion-icon><br>
            <ion-label color="dark" class="small-text">ALERTS</ion-label>
          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-center" style="cursor:pointer;" @click.prevent="changeView('profile-view')">
            <ion-icon :icon="person" color="dark"></ion-icon><br>
            <ion-label color="dark" class="small-text">PROFILE</ion-label>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <ion-action-sheet
      :is-open="isOpenRef"
      header="Create"
      css-class="aw-create-post"
      :buttons="buttons"
      @onDidDismiss="setOpen(false)"
    >
    </ion-action-sheet>
  </div>
</template>

<script>
import { IonActionSheet, IonLabel, IonIcon, IonRow, IonCol, modalController } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { home, notifications, person, compass, add } from 'ionicons/icons';
import { useStore } from "vuex";
import PostForm from './modals/PostForm.vue';
import ConcertForm from './modals/ConcertForm.vue';
import ClassifiedForm from './modals/ClassifiedForm.vue';

export default defineComponent({
  name: 'Tabs',
  props: ['page'],
  components: { IonActionSheet, IonLabel, IonIcon, IonRow, IonCol },
  setup(props) {
    const store = useStore();
    const dismissModal = computed(() => store.state.dismissModal);
    const currentModal = ref(null);
    const router = useRouter();
    const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    const postType = ref('post');
    const buttons = [
      {
        text: 'Post',
        handler: () => {
          postType.value = 'post'
          openModal()
          store.commit('setDismissModal', false)   
        },
      },
      {
        text: 'Concert',
        handler: () => {
          postType.value = 'concert'
          openModal()
          store.commit('setDismissModal', false)   
        },
      },
      {
        text: 'Classified',
        handler: () => {
          postType.value = 'classified'
          openModal()
          store.commit('setDismissModal', false)   
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked')
        },
      },
    ];

    function changeView(view) {
      if(view == 'create') {
        setOpen(true)
      } else {
        var path = null
        if(props.page == 'profile' || props.page == 'followers' || props.page == 'following' || props.page == 'post-single' || props.page == 'manage-post' || props.page == 'page' || props.page == 'scene') {
          path = '../' + view
        } else {
          path = view
        }
        router.push(path) 
      }
    }

    async function openModal() {
      let modalData = []
      // Post
      if(postType.value == 'post') {
         modalData = {
          component: PostForm,
          componentProps: {
            title: 'Create Post',
          },
        }
      }
      // Concert
      if(postType.value == 'concert') {
         modalData = {
          component: ConcertForm,
          componentProps: {
            formTitle: 'Create Concert',
          },
        }
      }
      // Classified
      if(postType.value == 'classified') {
         modalData = {
          component: ClassifiedForm,
          componentProps: {
            
          },
        }
      }
      const modal = await modalController.create(modalData);

      await modal.present();
      currentModal.value = modal;
    }

    function closeModal() {
      if (currentModal.value) {
        currentModal.value.dismiss().then(() => { currentModal.value = null; });
        store.commit('setDismissModal', false)
      }
    }

    return {
      home, notifications, person, compass, add, router, changeView, isOpenRef, setOpen, buttons, dismissModal, closeModal, openModal, postType
    }
  },
  watch: {
    dismissModal: function() {
      if(this.dismissModal === true) {
        this.closeModal()
      }
    }
  }
});
</script>

<style scoped>
  .footer-tabs {
    padding:5px 0px 20px 0px;
    background:#f4f5f8!important;
    border-top:1px solid #b0b2b9;
    width:100%;
    position: absolute;
    bottom: 0;
    left:0;
  }
  .footer-container {
    width:100%;
    max-width:760px;
    margin:0 auto;
  }
  .footer-row {
    display: flex;
    flex-wrap: wrap;    
  }
  .footer-col {
    flex: 1;
    background: tomato;
    height: 75px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .small-text {
    font-size:10px!important;
  }
  ion-icon {
    font-size:22px;
  }
</style>
