<template>
	<div v-if="post">
		<ion-card>
			<div>
				<img v-if="postImage" :src="postImage" @click.prevent="goToPost()">
			</div>
			<!-- Content -->
			<ion-card-content>

				<ion-row>
					<!-- Date -->
					<ion-col size="2" class="text-center">
						<p class="date-number margin-bottom-0 text-dark">{{postDay}}</p>
						<p class="month-text text-medium">{{postMonth}}</p>
					</ion-col>
					<!-- 
						Post Has No Image 
					-->
					<!-- Title -->
					<ion-col v-if="postImage == null" size="10">
						<p class="margin-bottom-0 text-dark text-capitalize bold">{{postTitle}}</p>
						<p class="margin-bottom-0 text-capitalize text-medium">{{postExcerpt}}</p>
						<p v-if="venue" class="margin-bottom-0 text-capitalize text-medium">{{venue.name}}</p>
					</ion-col>
					<!-- 
						Post Has Image 
					-->
					<!-- Title -->
					<ion-col v-if="postImage != null" size="7">
						<p class="margin-bottom-0 text-dark text-capitalize bold pointer" @click.prevent="goToPost()">
							{{postTitle}}
						</p>
						<p class="margin-bottom-0 text-capitalize text-medium">{{postExcerpt}}</p>
						<p v-if="venue" class="margin-bottom-0 text-capitalize text-medium">{{venue.name}}</p>
					</ion-col>
				</ion-row>
				<!-- 
					Post Bottom 
				-->
				<div v-if="postSlug && post.id > 0">
					<PostBottom 
						:slug="postSlug" 
						:post-id="post.id" 
						:display="display" 
						:comment-count="post.comment_count"
						:post-author-id="post.user_id" 
					/>
				</div>	

			</ion-card-content>
		</ion-card>
	</div>
</template>

<script>
import { IonCard,IonCardContent, IonRow, IonCol } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from "vuex";
//import apiClient from '../services/api';
import { useRouter } from 'vue-router';
import { parseISO, format } from 'date-fns'
import PostBottom from './PostBottom.vue';

export default defineComponent( {
	components: { IonCard, IonCardContent, IonRow, IonCol, PostBottom },
	name: 'ConcertCard',
	props: ['post', 'isAuth', 'display'],
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const postSlug = ref(null);
		const postTitle = ref(null);
		const postImage = ref(null);
		const postDate = ref(null);
		const postDay = ref(null);
		const postMonth = ref(null);
		const postExcerpt = ref(null);
		const venue = ref([]);
		
		onMounted(() => {
			doPost()
		})

		async function doPostDate(d) {
			postDate.value = await format(parseISO(d), 'yyyy-MM-dd');
			postDay.value = await format(parseISO(d), 'dd');
			postMonth.value = await format(parseISO(d), 'MMM').toUpperCase();
		}

		function doPost() {
			if(props.post.filename != null && props.post.filename != '') {
				postImage.value = props.post.filename
			}
			if(props.post.title != null && props.post.title != '') {
				if(props.post.title.length > 36) {
					let trimmedTitle = props.post.title.substring(0, 36)
					postTitle.value = trimmedTitle + '...'
				} else {
					postTitle.value = props.post.title
				}
			}
			if(props.post.body != null && props.post.body != '') {
				if(props.post.body.length > 20) {
					let trimmedString = props.post.body.substring(0, 20)
					postExcerpt.value = trimmedString + '...'
				} else {
					postExcerpt.value = props.post.body
				}
			}	

			postSlug.value = props.post.slug

			if(props.post.event) {
				doPostDate(props.post.event.date_time)
				if(props.post.event.venue) {
					venue.value = props.post.event.venue
					if(props.post.event.venue.name.length > 20) {
						let trimmedVenueName = props.post.event.venue.name.substring(0, 20)
						venue.value.name = trimmedVenueName + '...'
					} 
				}
			}		
		}

		function goToPost() {
			router.push('/post/'+postSlug.value)
		}

		return {
			router, store, postTitle, postImage, postDate, postDay, postMonth, postExcerpt, venue, postSlug, goToPost
		}
	},
	watch: {

	}
});
</script>

<style scoped>
	ion-thumbnail {
		min-width:100%;
		height:auto;
		/*min-height:64px;*/
	}
	.bold {
		font-weight:bold;
	}
	.margin-bottom-0 {
		margin-bottom:0px!important;
	}
	.date-number {
		font-size:22px;
	}
	.month-text {
		font-size:14px;
	}
	ion-card {
		margin-top:0px;
		margin-bottom:10px;
		margin-left:0px;
		margin-right:0px;
		border-radius:0px;
	}
</style>